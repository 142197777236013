import React, { useState, useEffect } from 'react'

const { id } = window.LTN.user

const Rating = ({ comment, isResult, handleReportModal }) => {
  const [liked, setLiked] = useState(false)
  const [disliked, setDisliked] = useState(false)
  const [likes, setLikes] = useState(0)
  const [dislikes, setDislikes] = useState(0)
  const [score, setScore] = useState(0)
  const [deleted, setDeleted] = useState(false)

  const guest = {
    cursor: !id && 'default'
  }

  const canAction = id && comment.author.id !== id;

  useEffect(() => {
    setLikes(comment.likes)
    setDislikes(comment.dislikes)

    if (comment && comment.score) {
      setScore(comment.score)
    }
  }, [])

  // Determines what action to take when the the user clicks the icon
  const setRating = ratingType => {
    if (!isResult && id) {
      if (
        (score === 1 && ratingType === 'like') ||
        (score === -1 && ratingType === 'dislike')
      ) {
        setLiked(false)
        setDisliked(false)
        setDeleted(true)

        return deleteRating()
      }

      setDeleted(false)
      return postRating(ratingType)
    }
  }

  const deleteRating = () => {
    return axios
      .delete(`/api/comments/${comment.id}/likes`, {
        headers: {
          Authorization: `Bearer ${document.head.querySelector('meta[name="api-token"]').content
            }`
        }
      })
      .then(() => {
        setLiked(false)
        setDisliked(false)
        setLikes(0)
        setDislikes(0)
        setScore(0)
      })
      .catch(err => {
        console.error('oops, unable to remove rating', err)
        alert(
          'Sorry there was a problem when posting your response. Please try again.'
        )
      })
  }

  const postRating = ratingType => {
    if (ratingType === 'like') {
      setLiked(!liked)
      setDisliked(false)

      const score = 1
      postData(score)
    } else {
      setDisliked(!disliked)
      setLiked(false)

      const score = -1
      postData(score)
    }
  }

  const postData = score => {
    return axios
      .post(
        `/api/comments/${comment.id}/likes`,
        {
          score
        },
        {
          headers: {
            Authorization: `Bearer ${document.head.querySelector('meta[name="api-token"]').content
              }`
          }
        }
      )
      .then(response => {
        const { likes, dislikes, score } = response.data.WallComment

        setLikes(likes)
        setDislikes(dislikes)
        setScore(score)
      })
      .catch(err => {
        console.error('Unable to post reaction due to error', err)
        alert(
          'Sorry there was a problem when posting your response. Please try again.'
        )
      })
  }

  const getClassName = type => {
    if (deleted) {
      return 'far'
    }

    if (type === 'like') {
      if (liked) {
        return 'fas'
      }

      if (comment.score === 1) {
        return 'fas'
      }

      return 'far'
    } else if (type === 'dislike') {
      if (disliked) {
        return 'fas'
      }

      if (comment.score === -1) {
        return 'fas'
      }

      return 'far'
    }
  }

  return (
    <>
      <ul>
        <li>
          <div className="c-wall__thread-rating--thumbs-up" style={guest}>
            {likes}
            <span className="sr-only"> Likes  </span>
            <button onClick={() => setRating('like')} className="c-button__rating" aria-label="Like" disabled={!canAction}>
              <i
                className={`${getClassName('like')} fa-thumbs-up`}
              />
            </button>
          </div>
        </li>
        <span className="c-wall__thread-divider">|</span>
        <li>
          <div className="c-wall__thread-rating--thumbs-down" style={guest}>
            <span className="sr-only"> Dislikes  </span>
            {dislikes}
            <button onClick={() => setRating('dislike')} className="c-button__rating" aria-label="Deslike" disabled={!canAction}>
              <i
                className={`${getClassName('dislike')} fa-thumbs-down`}
              />
            </button>
          </div>
        </li>
        {canAction && (
          <>
            <span className="c-wall__thread-divider">|</span>
            <li>
              <div>
                <button
                  aria-label="Report"
                  className="c-button__rating c-button c-button--link"
                  type="button"
                  onClick={handleReportModal}
                >
                  Report
                </button>
              </div>
            </li>
          </>
        )}
      </ul>
    </>
  )
}

export default Rating
