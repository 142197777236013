import React from 'react'

const Header = ({ title }) => (
  <div className="c-poll__header">
    <div>
      <h2 className="c-poll__header-title">{title || 'Topic Wall'}</h2>
    </div>

    <div>
      <img src="/images/icons/comments-icon-small.png" alt="Discussion" />
    </div>
  </div>
)

export default Header
