import React, { useState, useEffect } from 'react'
import {
  Text,
  Textarea,
  Rating,
  Dropdown,
  Radio,
  Numeric,
  Checkbox,
} from './QuestionTypes'

const SurveyPage = ({
  error,
  surveyLength,
  questions,
  pageNumber,
  currentPage,
  onChange,
  onPayloadChange,
  hasResponded,
  initialAnswers,
}) => {
  const [, setTextValue] = useState('')
  const [, setNumberValue] = useState('')
  const [, setRatingValue] = useState(0)
  const [checkedValues, setCheckedValues] = useState([])
  const [, setTextareaValue] = useState('')
  const [, setRadioValue] = useState('')
  const [, setDropdownValue] = useState('')
  const [answeredQuestions, setAnsweredQuestions] = useState(0)

  // Gather Page results to pass up to the survey.
  const [pageResults, setPageResults] = useState({})

  useEffect(() => {
    setPageResults({
      pageId: pageNumber,
      questions: questions.map((question) => ({
        questionId: question.id,
        value: '',
        text: question.text,
        label: question.label,
        type: question.type,
        options: question.options,
        required: question.required,
      })),
    })
  }, [questions])

  const handleCallback = (value, id, type) => {
    switch (type) {
      case 'text':
        setTextValue(value)
        break
      case 'textarea':
        setTextareaValue(value)
        break
      case 'numeric':
        setNumberValue(value)
        break
      case 'rating':
        setRatingValue(value)
        break
      case 'choice':
        setRadioValue(value)
        break
      case 'choice-multi':
        setCheckedValues(value)
        break
      case 'dropdown':
        setDropdownValue(value)
        break
      default:
        break
    }

    const question = pageResults.questions.find(
      (result) => result.questionId === id
    )

    if (question) {
      question.value = value
    }

    let answered = []
    pageResults.questions.forEach((question) => {
      if (question.value) {
        if (Array.isArray(question.value) && question.value.length > 0) {
          answered.push(question)
        } else if (!Array.isArray(question.value) && question.value !== '') {
          // This is not an array, it's a string
          answered.push(question)
        }
      }

      // Sometimes values are returned as objects so we test for the
      // existence of the object and push the value if it exsits.
      if (question.value && question.value.value) {
        if (question.value.value.length > 0) {
          answered.push(question)
        }
      }
    })

    setAnsweredQuestions(answered.length)
  }

  useEffect(() => {
    let answersByQuestionId = []

    Object.keys(initialAnswers).length &&
      Object.keys(initialAnswers).forEach((key, i) => {
        answersByQuestionId.push(initialAnswers[key].questionId)
      })

    setAnsweredQuestions([...new Set(answersByQuestionId)].length)

    if (Object.keys(initialAnswers).length) {
      // Do some checks on questions array
      const getAnswerFromArray = (answers, question) => {
        const length = answers.length
        const foundAnswer = answers.filter(
          (answer) => answer.questionId === question.id
        )[length - 1]

        return foundAnswer
      }

      setPageResults({
        ...pageResults,
        questions: questions.map((question) => ({
          questionId: question.id,
          value:
            initialAnswers[question.id] &&
              Array.isArray(initialAnswers[question.id])
              ? getAnswerFromArray(initialAnswers[question.id], question)
              : initialAnswers[question.id]
                ? initialAnswers[question.id].value
                : '',
          text: question.text,
          label: question.label,
          type: question.type,
          options: question.options,
          required: question.required,
        })),
      })
    }
  }, [initialAnswers])

  let canSubmit = true;
  if (pageResults && pageResults.questions) {
    pageResults.questions.forEach(question => {
      if (!!question.required) {
        if (Array.isArray(question.value)) {
          if (question.value.length === 0) {
            canSubmit = false;
          }
        } else {
          if (!!!question.value) {
            canSubmit = false;
          }
        }
      }
    });
  }

  return (
    currentPage == pageNumber - 1 && (
      <div className="c-poll">
        <ol className="o-list o-list--number c-poll__list">
          {pageResults.questions &&
            pageResults.questions.map((question, i) => {
              const { label, text, type } = question

              return (
                <li
                  key={i}
                  className="o-media o-list__number c-poll__list-item c-poll__result"
                >
                  {' '}
                  <div className="o-media__body u-text-light">
                    <div className="c-poll__question">
                      <label htmlFor={question.questionId}>
                        <p>{label}</p>
                        <p>{text}</p>
                      </label>
                    </div>
                    <div>
                      {/* Depending on Question Type, Display Different input groups */}
                      {type === 'text' && (
                        <Text
                          textCallback={handleCallback}
                          initialAnswers={
                            initialAnswers[question.questionId] || []
                          }
                          id={question.questionId}
                          type={type}
                          onChange={onChange}
                          required={!!question.required}
                        />
                      )}

                      {type === 'textarea' && (
                        <Textarea
                          textareaCallback={handleCallback}
                          initialAnswers={
                            initialAnswers[question.questionId] || []
                          }
                          id={question.questionId}
                          type={type}
                          onChange={onChange}
                          required={!!question.required}
                        />
                      )}

                      {type === 'numeric' && (
                        <Numeric
                          numericCallback={handleCallback}
                          id={question.questionId}
                          defaultValue={question.value}
                          type={type}
                          onChange={onChange}
                          required={!!question.required}
                        />
                      )}

                      {type === 'rating' && (
                        <Rating
                          options={question.options}
                          id={question.questionId}
                          initialAnswers={
                            initialAnswers[question.questionId] || []
                          }
                          ratingCallback={handleCallback}
                          type={type}
                          onChange={onChange}
                          required={!!question.required}
                        />
                      )}

                      {type === 'dropdown' && (
                        <Dropdown
                          id={question.questionId}
                          options={question.options}
                          dropdownCallback={handleCallback}
                          initialAnswers={
                            initialAnswers[question.questionId] || []
                          }
                          type={type}
                          onChange={onChange}
                          required={!!question.required}
                        />
                      )}

                      {type === 'choice' && (
                        <Radio
                          id={question.questionId}
                          options={question.options}
                          radioCallback={handleCallback}
                          value={question.value}
                          type={type}
                          onChange={onChange}
                          required={!!question.required}
                        />
                      )}

                      {type === 'choice-multi' && (
                        <>
                          <Checkbox
                            id={question.questionId}
                            initialCheckedValues={
                              initialAnswers[question.questionId] || []
                            }
                            options={question.options}
                            optionsCallback={handleCallback}
                            type={type}
                            onChange={onChange}
                            required={!!question.required}
                          />
                        </>
                      )}

                      {!!question.required && <small style={{ display: 'block', textAlign: 'right', paddingTop: '0.25rem' }}>Required</small>}
                    </div>
                  </div>
                </li>
              )
            })}
        </ol>

        <div className="c-poll__footer" style={{ display: 'block' }}>
          {error && (
            <p style={{ marginBottom: 16 }}>
              Sorry there was an error submitting your response. Please ensure
              all questions are answered.
            </p>
          )}
          {/* {!hasResponded && ( */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {pageNumber > 0 && pageNumber < surveyLength ? (
              <a
                className="c-button c-button--secondary"
                onClick={() => {
                  canSubmit
                    ? onPayloadChange(pageResults, currentPage, 'next')
                    : alert(
                      'Please ensure all required questions are answered before changing pages'
                    )
                }}
              >
                Next
              </a>
            ) : (
              pageNumber === surveyLength && (
                <button
                  className="c-button c-button--secondary"
                  onClick={() => {
                    canSubmit
                      ? onPayloadChange(pageResults, currentPage, 'submit')
                      : alert(
                        'Please ensure all required questions are answered before submitting'
                      )
                  }}
                >
                  Submit
                </button>
              )
            )}
          </div>
        </div>
      </div>
    )
  )
}
export default SurveyPage
