import React, { useState, useRef } from 'react'
import Clipboard from 'react-clipboard.js'

const EmbedTooltip = ({ variant, id }) => {
  const [copySuccess, setCopySuccess] = useState(false)
  const [wording, setWording] = useState('Copy HTML')

  const markup = `<iframe width="500" height="500" src="http://letstalknewcastle.co.uk/embed/${variant}/${id}" frameborder="0" allowfullscreen></iframe>`

  return (
    <div
      role="tooltip"
      className={`c-embed-tooltip ${
        variant === 'surveys' && 'c-embed-tooltip--survey'
      }`}
    >
      <textarea className="c-embed-tooltip__link" disabled value={markup} />
      {!copySuccess ? (
        <Clipboard
          className="c-embed-tooltip__button"
          data-clipboard-text={markup}
          onSuccess={() => setCopySuccess(true)}
        >
          {wording}
        </Clipboard>
      ) : (
        <p className="c-embed-tooltip__button">Copied!</p>
      )}
    </div>
  )
}

export default EmbedTooltip
