import React, { useState, useEffect } from 'react'
import localForage from 'localforage'
import Results from './Results'
import EmbedTooltip from '../EmbedTooltip'
import CompletionModal from '../CompletionModal'

const axios = require('axios')
const { id } = window.LTN.user

// Survey Component
const Header = ({ id }) => {
  const [embedTooltip, embedTooltipVisible] = useState(false)
  return (
    <div className="c-poll__header">
      <div>
        <h2 className="c-poll__header-title">Survey</h2>
      </div>

      <div>
        <div style={{ position: 'relative' }}>
          <button
            className="c-button c-button--small c-button--embed"
            onClick={() => embedTooltipVisible(!embedTooltip)}
            type="button"
          >
            Embed Survey
          </button>
          {embedTooltip && <EmbedTooltip variant="surveys" id={id} />}
        </div>
      </div>
    </div>
  )
}

const Body = props => (
  <div className="c-poll__body">
    <div className="c-poll__meta">
      <h3>{props.title}</h3>
      <p
        className="u-text-light"
        dangerouslySetInnerHTML={{ __html: props.description }}
      />
    </div>

    {props.children}
  </div>
)

const Survey = props => {
  const [startedFlag, setStartedFlag] = useState(false)
  const [surveyResponseId, setSurveyResponseId] = useState()
  const [payload, setPayload] = useState([])
  const [error, setError] = useState(false)
  const [hasResponded, setHasResponded] = useState(false)
  const [hasCompleted, setHasCompleted] = useState(false)
  const [completionModal, setCompletionModal] = useState(false)
  const [initialAnswers, setInitialAnswers] = useState([])
  const [loading, setLoading] = useState({ message: '', loading: false })

  useEffect(() => {
    const fetchedSurveyIds = async () => {
      const respondedSurveyIds =
        (await localForage.getItem('responded_survey_ids')) || []
      setHasResponded(respondedSurveyIds.includes(props.id))
    }

    if (id) {
      setHasResponded(props.hasResponded)
      setHasCompleted(props.hasCompleted)
    } else {
      fetchedSurveyIds()
    }
  }, [])

  useEffect(() => {
    const getCompletedSurveyModals = async () => {
      const startedSurveys = (await localForage.getItem('surveys')) || []
      const surveyIdCheck = startedSurveys.find(
        ({ surveyId }) => surveyId === props.id
      )

      if (surveyIdCheck && surveyIdCheck.completed === true) {
        setHasCompleted(true)

        if (props.userProfileCompletion && id) {
          setCompletionModal(true)
        }
      }
    }

    getCompletedSurveyModals()
  }, [])

  useEffect(() => {
    setPayload(
      props.pages.map(page =>
        page.questions.map(question => ({
          questionId: question.id,
          response: ''
        }))
      )
    )
  }, [])

  useEffect(() => {
    const getCurrentSurvey = async () => {
      const startedSurveys = (await localForage.getItem('surveys')) || []
      if (startedSurveys.length) {
        return startedSurveys.find(({ surveyId }) => surveyId === props.id)
      }
    }

    getCurrentSurvey().then(res => {
      if (res) {
        if (res.surveyId) {
          setSurveyResponseId(res.responseId)
          setLoading({ message: 'Loading survey...', loading: true })

          axios({
            method: 'get',
            url: `/api/survey-responses/${res.responseId}`,
            headers: {
              Authorization: `Bearer ${document.head.querySelector('meta[name="api-token"]').content
                }`
            }
          })
            .then(({ data, status }) => {
              if (status === 200) {
                setInitialAnswers(data.SurveyResponse.answers)
                setLoading({ message: '', loading: false })
              }
            })
            .catch(({ response }) => {
              // If 403, user must be logged in
              if (response.status === 403) {
                setLoading({
                  message: 'Please login to take part in the survey',
                  loading: true
                })
              }
            })
        }
      }
    })
  }, [])

  function debounce(func, wait, immediate) {
    var timeout
    return function () {
      var context = this,
        args = arguments
      var later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      var callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }

  const delayedCallback = debounce(e => {
    submitStartedAt()
  }, 500)

  const handleChange = e => {
    setStartedFlag(true)
    e && e.persist()
    if (!startedFlag && !surveyResponseId) {
      delayedCallback(e)
    }
  }

  const submitStartedAt = async () => {
    const { data, status } = await axios
      .post(
        `/api/surveys/${props.id}`,
        { responses: payload.flat() },
        {
          headers: {
            Authorization: `Bearer ${document.head.querySelector('meta[name="api-token"]').content
              }`
          }
        }
      );

    setSurveyResponseId(data.SurveyResponse.id)

    const startedSurveys = (await localForage.getItem('surveys')) || []
    startedSurveys.push({
      surveyId: props.id,
      responseId: data.SurveyResponse.id,
      responses: data.SurveyResponse.answers,
      completed: false
    })

    await localForage.setItem('surveys', startedSurveys)
    return data
  }

  const handlePageTransition = async (payload, pageIndex) => {
    let _surveyResponseId = surveyResponseId;
    if (!startedFlag && !surveyResponseId) {
      const data = await submitStartedAt();
      _surveyResponseId = data.SurveyResponse.id
    }

    const updatedPayload = {
      responses: payload[pageIndex].pageResults
    }

    setPayload(updatedPayload)

    axios
      .put(`/api/survey-responses/${_surveyResponseId}`, updatedPayload, {
        headers: {
          Authorization: `Bearer ${document.head.querySelector('meta[name="api-token"]').content
            }`
        }
      })
      .then(async ({ data }) => {
        const surveyResponse = data.SurveyResponse
        const startedSurveys = (await localForage.getItem('surveys')) || []
        const currentSurveyIndex = startedSurveys.findIndex(
          ({ surveyId }) => surveyId === props.id
        )
        const currentSurvey = startedSurveys[currentSurveyIndex]

        // Grab our current recording of Survey answers
        const myResponses = currentSurvey.responses

        // Loop the returned answers to record them
        if (surveyResponse) {
          Object.keys(surveyResponse.answers).forEach(key => {
            if (Array.isArray(surveyResponse.answers[key])) {
              surveyResponse.answers[key].forEach(returnedAnswer => {
                // See if we have a copy of an answer already
                const answerIndex = myResponses.findIndex(
                  myAnswer => myAnswer.questionId === returnedAnswer.questionId
                )

                // If we do, update the value...
                if (answerIndex !== -1) {
                  myResponses[answerIndex].value = returnedAnswer.value

                  // ...otherwise add it to the list
                } else {
                  myResponses.push(returnedAnswer)
                }
              })
            }
          })
        }

        // Update our Survey cache with the updated list
        startedSurveys[currentSurveyIndex].responses = myResponses

        // Save back to Local Storage
        localForage.setItem('surveys', startedSurveys)
      })
      .catch((error, res) => {
        console.log('error', error, res)
        setError(true)
      })
  }

  const handleFormSubmit = async payload => {
    let _surveyResponseId = surveyResponseId;
    if (!startedFlag && !surveyResponseId) {
      const data = await submitStartedAt();
      _surveyResponseId = data.SurveyResponse.id
    }

    let payloadToSend = {}

    if (Array.isArray(payload)) {
      const pageResults = payload.map(pages => pages.pageResults)

      const updatedPayload = {
        responses: [].concat.apply([], pageResults)
      }

      updatedPayload.completed = true

      payloadToSend = updatedPayload
    } else {
      const pageResults = Object.keys(payload).map(key => {
        return payload[key].pageResults
      })

      const updatedPayload = {
        responses: [].concat.apply([], pageResults)
      }

      updatedPayload.completed = true

      payloadToSend = updatedPayload
    }

    axios
      .put(`/api/survey-responses/${_surveyResponseId}`, payloadToSend, {
        headers: {
          Authorization: `Bearer ${document.head.querySelector('meta[name="api-token"]').content
            }`
        }
      })
      .then(async () => {
        const respondedSurveyIds =
          (await localForage.getItem('responded_survey_ids')) || []
        respondedSurveyIds.push(props.id)

        const startedSurveys = (await localForage.getItem('surveys')) || []
        const currentSurveyIndex = startedSurveys.findIndex(
          ({ surveyId }) => surveyId === props.id
        )
        const currentSurvey = startedSurveys[currentSurveyIndex]

        currentSurvey.completed = true

        try {
          await localForage.setItem('surveys', startedSurveys)
          await localForage.setItem('responded_survey_ids', respondedSurveyIds)
        } catch (err) {
          console.log(err)
        }

        setHasResponded(true)
        setHasCompleted(true)

        if (
          window.LTN.user &&
          window.LTN.user.id &&
          props.userProfileCompletion < 100
        ) {
          setCompletionModal(true)
        }
      })
      .catch(function (error) {
        console.log(error)
        setError(true)
      })
  }

  return (
    <div className="c-poll c-poll--survey">
      <Header
        embedTooltipCallback={() => embedTooltipVisible(!embedTooltip)}
        id={props.id}
        category={props.category}
        type={props.type}
      />

      {!loading.loading ? (
        <Body
          title={props.title}
          description={props.description}
          createdAt={props.createdAt}
        >
          {completionModal && (
            <CompletionModal
              referenceId={props.id}
              setCompletionModal={() => setCompletionModal(false)}
            />
          )}
          {!props.isResult ? (
            <div>
              {!hasCompleted ? (
                <form
                  id={`survey-form-${props.id}`}
                  onSubmit={e => {
                    e.preventDefault()
                    // handleFormSubmit(payload)
                  }}
                >
                  <Results
                    error={error}
                    initialAnswers={initialAnswers}
                    onChange={handleChange}
                    hasResponded={hasResponded}
                    onPageTransition={handlePageTransition}
                    onFormSubmit={handleFormSubmit}
                    pages={props.pages}
                  />
                </form>
              ) : (
                <div className="c-poll__footer">
                  <p aria-live="assertive" role="alert">
                    Thank you for taking part in this survey.
                    {!id && (
                      <span>
                        {' '}
                        Please consider <a href="/login">logging in </a> or{' '}
                        <a href="/register">Registering</a> for an improved
                        experience.
                      </span>
                    )}
                  </p>
                </div>
              )}
            </div>
          ) : (
            <p className="u-margin-top--small">
              This participation phase for this survey is now over.
              {!id && (
                <span>
                  {' '}
                  Please consider <a href="/login">logging in </a> or{' '}
                  <a href="/register">Registering</a> for an improved
                  experience.
                </span>
              )}
            </p>
          )}
        </Body>
      ) : (
        <p className="c-poll__loading">{loading.message}</p>
      )}
    </div>
  )
}

export default Survey
