class MenuTrigger {
  constructor(element = "menu-trigger-js") {
    this.element = document.getElementById(element);
    this.menu = document.getElementById("menu-panel-js");
    this.overlay = document.getElementById("overlay-js");
    this.burger = document.getElementById("menu-icon-trigger-js");

    // Bind this to click callback
    this._handleClick = this._handleClick.bind(this);

    const self = this;

    this.element.addEventListener("click", function() {
      self._handleClick();
    });

    this.overlay.addEventListener("click", function() {
      self._handleClick();
    });
  }

  // If the user clicks on the element then we need to append
  // the active class to the menu, overlay and icon.
  _handleClick() {
    const isCurrentlyOpen = this.menu.classList.contains('c-menu--active');
    this.burger.setAttribute('aria-label', !isCurrentlyOpen ? 'Close Menu' : 'Open Menu');

    this.menu.classList.toggle("c-menu--active");
    this.overlay.classList.toggle("c-overlay--active");
    this.burger.classList.toggle("is-active");
    document.querySelector("body").classList.toggle("u-prevent-scroll");
  }
}

export default MenuTrigger;
