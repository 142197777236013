import React, { useState, useEffect } from 'react'

// Individual checkbox
const Checkbox = ({ defaultChecked, value, name, onChange }) => {
  return (
    <input
      defaultChecked={defaultChecked}
      type="checkbox"
      value={value}
      name={name}
      className="c-input c-input--checkbox"
      onClick={onChange}
    />
  )
}

// Multiple Option Selection
const Checkboxes = ({
  initialCheckedValues,
  onChange,
  id,
  type,
  optionsCallback,
  options,
}) => {
  const [selectedValues, setSelectedValues] = useState([])

  // When the api request has loaded, we set the selected values
  // to be the initial values passed from api
  useEffect(() => {
    setSelectedValues(initialCheckedValues)
  }, [])

  const handleCheck = (e, value) => {
    onChange(e)

    // If the user checks a box then we need to check if this was part
    // of the initial checked values
    if (e.target.checked) {
      const existingItem = () => {
        if (Array.isArray(initialCheckedValues)) {
          return initialCheckedValues.find(
            (checkedValue) => checkedValue.value === value
          )
        }

        return [initialCheckedValues].find(
          (checkedValue) => checkedValue.value === value
        )
      }

      // If we have an existing item then send it up to the array
      if (existingItem()) {
        setSelectedValues([...selectedValues, existingItem])
      } else {
        // Otherwise just add it to the array
        if (selectedValues.length) {
          setSelectedValues([selectedValues, e.target.value])
        } else {
          setSelectedValues([e.target.value])
        }
      }
    } else {
      // If the checkbox is unchecked then we do something similar
      // but this time we're removing an item...
      let hasObject = false

      if (Array.isArray(selectedValues)) {
        selectedValues.forEach((item) => {
          hasObject = Boolean(item.value)
        })
      } else {
        ;[selectedValues].forEach((item) => {
          hasObject = Boolean(item.value)
        })
      }

      if (hasObject) {
        if (Array.isArray(selectedValues)) {
          setSelectedValues(
            selectedValues.filter((item) => item.value !== value)
          )
        } else {
          setSelectedValues(
            [selectedValues].filter((item) => item.value !== value)
          )
        }
      } else {
        setSelectedValues(
          selectedValues.filter((checkedItem) => checkedItem !== value)
        )
      }
    }
  }

  useEffect(() => {
    const setValuesToSend = () => {
      return selectedValues.map((v) => {
        if (Array.isArray(v)) {
          return v.toString()
        }
        return v
      })
    }

    const valuesToSend = setValuesToSend()

    optionsCallback(valuesToSend, id, type)
  }, [selectedValues])

  return (
    <fieldset id={id} className="c-poll__selection c-poll__selection--survey">
      {options &&
        options.map((option, i) => (
          <label key={i}>
            <div className="c-poll__option">
              <Checkbox
                defaultChecked={
                  Array.isArray(initialCheckedValues)
                    ? initialCheckedValues.find(
                      (checkedValue) => checkedValue.value === option.value
                    )
                    : [initialCheckedValues].find(
                      (checkedValue) => checkedValue.value === option.value
                    )
                }
                type="checkbox"
                value={option.value}
                name={`group-${id}`}
                className="c-input c-input--checkbox"
                onChange={(e) => handleCheck(e, option.value)}
              />

              <p>{option.label}</p>
            </div>
          </label>
        ))}
    </fieldset>
  )
}

export default Checkboxes
