// Sorting
export default function ConsultationSort() {
  const baseUrl = window.location.pathname || '/';

  const params = new URLSearchParams(window.location.search)
  const sortBy = document.getElementById('consultation-sort-by')

  function consultationSort() {
    const sortValues = sortBy.options[sortBy.selectedIndex].value.split(', ')

    const newParams = {}

    for (const [key, value] of params.entries()) {
      newParams[key] = value;
    }

    if (sortValues.length) {
      newParams['consultations[sort-by]'] = sortValues[0];
      newParams['consultations[sort-order]'] = sortValues[1];
    }

    const paramString = Object.keys(newParams).length ? new URLSearchParams(newParams).toString() : '';

    window.location = `${baseUrl}?${paramString}`
  }

  sortBy.addEventListener('change', function() {
    consultationSort()
  })
}
