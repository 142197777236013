import React, { useState, useEffect } from 'react'
import localForage from 'localforage'
import EmbedTooltip from '../EmbedTooltip'
import CompletionModal from '../CompletionModal'
const axios = require('axios')

const { id, name } = window.LTN.user

const Header = ({ category, id, title }) => {
  const [embedTooltip, embedTooltipVisible] = useState(false)

  return (
    <div className="c-poll__header">
      <div>
        <h2 className="c-poll__header-title">{title || 'Poll'}</h2>
      </div>
      <div>
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            marginLeft: 12
          }}
        >
          <button
            className="c-button c-button--small c-button--embed"
            onClick={() => embedTooltipVisible(!embedTooltip)}
            type="button"
          >
            Embed Poll
          </button>
          {embedTooltip && <EmbedTooltip variant="polls" id={id} />}
        </div>
        {/* <img
          className="c-poll__header-icon"
          alt="Poll Icon"
          src="/images/icons/small-bar-icon.png"
        /> */}
      </div>
    </div>
  )
}

const Body = props => (
  <div className="c-poll__body">
    <fieldset>
      <legend className="c-poll__text u-text-medium">{props.title}</legend>
      <div
        className="c-poll__text u-text-light"
        dangerouslySetInnerHTML={{ __html: props.description }}
      />
      {props.children}
    </fieldset>
  </div>
)

const Results = ({
  isResult,
  options,
  selectedValueCallback,
  hasVoted,
  totalVotes
}) => (
  <>
    <div className="c-poll__selection">
      {(hasVoted || isResult) && (
        <div className="c-poll-results-label">
          <div>
            <p>Results</p>
          </div>
          <div>
            <p>Total Votes - {totalVotes}</p>
          </div>
        </div>
      )}
      {options.map((option, i) => (
        <label key={i}>
          <div className="c-poll__option">
            {hasVoted || isResult ? (
              <div className="c-poll__option-percentage">
                <p>{((option.votes / totalVotes) * 100).toFixed(2)}&#37;</p>
              </div>
            ) : (
              <input
                className="c-poll__radio"
                type="radio"
                name="poll_option_id"
                onClick={e => selectedValueCallback(e.target.value)}
                value={option.value}
                id={option.id}
              />
            )}
            <label aria-label={option.label} className="c-poll__option-label" htmlFor={option.id}>
              {option.label}
            </label>
          </div>
        </label>
      ))}
    </div>
  </>
)

const Poll = props => {
  const [options, setOptions] = useState([])
  const [totalVotes, setTotalVotes] = useState(0)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completionModal, setCompletionModal] = useState(false)
  const [useTitle, setUseTitle] = useState(false)

  useEffect(() => {
    setOptions(props.options)
    setTotalVotes(props.totalVotes)

    if (document.getElementById('popular-wrap')) {
      setUseTitle(true)
    }
  }, [])

  const [hasVoted, setHasVoted] = useState(false)

  useEffect(() => {
    const fetchedPollIds = async () => {
      const votedPollIds = (await localForage.getItem('voted_poll_ids')) || []
      setHasVoted(votedPollIds.includes(props.id))
    }

    if (id) {
      setHasVoted(props.hasVoted)
      setCompletionModal(props.userProfileCompletion < 100 && props.hasVoted)
    } else {
      fetchedPollIds()
    }
  }, [])

  const [selectedValue, setSelectedValue] = useState('')
  const payload = {
    value: selectedValue,
    author: {
      id: id,
      name: name
    },
    createdAt: new Date(Date.now()).toISOString()
  }

  const submitPoll = (e, payload, userProfileCompletion) => {
    e.preventDefault()
    setLoading(true)
    axios
      .post(
        `/api/polls/${props.id}/votes`,
        {
          value: payload.value
        },
        {
          headers: {
            Authorization: `Bearer ${
              document.head.querySelector('meta[name="api-token"]').content
            }`
          }
        }
      )
      .then(async response => {
        const votedPollIds = (await localForage.getItem('voted_poll_ids')) || []
        votedPollIds.push(props.id)

        try {
          await localForage.setItem('voted_poll_ids', votedPollIds)
        } catch (err) {
          console.log(err)
        }
        setOptions(response.data.Poll.options)
        setTotalVotes(response.data.Poll.totalVotes)
        setHasVoted(true)
        setLoading(false)
      })
      .catch(function(error) {
        console.log(error)
        setError(true)
      })

    if (window.LTN.user.id && userProfileCompletion < 100) {
      setCompletionModal(true)
    }
  }

  return (
    <div className="c-poll">
      <Header
        id={props.id}
        category={props.category}
        type={props.type}
        title={useTitle ? props.consultationTitle : null}
      />
      <Body description={props.description} title={props.title}>
        {completionModal && (
          <CompletionModal
            referenceId={props.id}
            setCompletionModal={() => setCompletionModal(false)}
          />
        )}
        <form
          onSubmit={e => submitPoll(e, payload, props.userProfileCompletion)}
        >
          <Results
            isResult={props.isResult}
            options={options}
            hasVoted={hasVoted}
            selectedValueCallback={setSelectedValue}
            totalVotes={totalVotes}
          />
          <div className="c-poll__footer u-text-light">
            {!props.isResult ? (
              <div>
                {!error ? (
                  !loading && !hasVoted ? (
                    <button className="c-button c-button--tertiary-darker">
                      Submit
                    </button>
                  ) : !hasVoted ? (
                    <p>Processing your vote...</p>
                  ) : (
                    !loading &&
                    hasVoted && (
                      <p aria-live="assertive" role="alert">
                        Thank you for taking part in this poll.{' '}
                        {!id && (
                          <span>
                            Please consider <a href="/login">logging in </a> or{' '}
                            <a href="/register">Registering</a> for an improved
                            experience.
                          </span>
                        )}
                      </p>
                    )
                  )
                ) : (
                  <p>Sorry, there was a problem registering your vote</p>
                )}
              </div>
            ) : (
              <p>
                The participation phase for this poll is now over.{' '}
                {!id && (
                  <span>
                    Please consider <a href="/login">logging in </a> or{' '}
                    <a href="/register">Registering</a> for an improved
                    experience.
                  </span>
                )}
              </p>
            )}
          </div>
        </form>
      </Body>
    </div>
  )
}

export default Poll
