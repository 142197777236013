import React from "react";

const Reply = ({ replyKey, replyContent }) => (
  <div
    className="c-wall__reply"
    style={{ direction: window.LTN.user.id == replyContent.author.id && 64 }}
    key={replyKey}
  >
    <p className="c-wall__reply-text">{replyContent.text}</p>
    <p className="c-wall__reply-author">{replyContent.author.name.full}</p>
  </div>
);

export default Reply;
