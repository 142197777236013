import React from 'react'

// Numeric Input
const Numeric = ({ defaultValue, id, numericCallback, type, onChange, required = false }) => {
  const handleChange = e => {
    const { value } = e.target
    numericCallback(value, id, type)
    onChange(e)
  }

  return (
    <input
      className="c-poll__input"
      id={id}
      onChange={e => handleChange(e)}
      placeholder="Enter Number"
      type="number"
      defaultValue={defaultValue.value || defaultValue}
      required={required}
    />
  )
}

export default Numeric
