export default function EventSort() {
  const baseUrl = window.location.pathname || '/';
  const params =
    window.location.search.length > 0
      ? window.location.search.substring(1).split('&')
      : []
  const sortBy = document.getElementById('event-sort-by')
  function eventSort() {
    const sortValues = sortBy.options[sortBy.selectedIndex].value.split(', ')
    const sortByIndex = params.findIndex(param =>
      param.includes('events[sort-by]')
    )
    const typeIndex = params.findIndex(param => param.includes('events[type]'))

    // If params exist
    if (params.length > 0) {
      // if there is a sort by parameter (then there is also sort-order - remove these two and replace.)
      if (sortByIndex > -1) {
        params.splice(sortByIndex, 2)

        // if more than 1 sort value then this is the sort-by/sort-order group
        if (sortValues.length > 1) {
          params.push(
            `events[sort-by]=${sortValues[0]}`,
            `events[sort-order]=${sortValues[1]}`
          )
        } else {
          params.push(`events[type]=${sortValues[0]}`)
        }
      }

      // if there is a type parameter
      if (typeIndex > -1) {
        params.splice(typeIndex, 1)
        if (sortValues.length > 1) {
          params.push(
            `events[sort-by]=${sortValues[0]}`,
            `events[sort-order]=${sortValues[1]}`
          )
        } else {
          params.push(`events[type]=${sortValues[0]}`)
        }
      }
    } else {
      if (sortValues.length > 1) {
        params.push(
          `events[sort-by]=${sortValues[0]}`,
          `events[sort-order]=${sortValues[1]}`
        )
      } else {
        params.push(`events[type]=${sortValues[0]}`)
      }
    }

    window.location.href = params.length && `${baseUrl}?${params.join('&')}#events`
  }

  sortBy.addEventListener('change', function() {
    eventSort()
  })
}
