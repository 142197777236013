/**
 * Tabbed View UI Component
 */

class TabView {
  constructor(target) {
    this.target = target
    this.panel = target.getAttribute('data-target')

    this._init()
  }

  _init() {
    this.target.addEventListener('click', event => this.handleClick(event))
  }

  handleClick(event) {
    const parent = event.target.parentNode
    const panel = document.getElementById(this.panel)

    if (event.target.getAttribute('data-filter-slug') !== 'all') {
      // Remove the is-active class from other filter elements
      parent.querySelector('.is-active').classList.remove('is-active')

      // Remove active class from existing panel, then add active class
      // to new panel
      panel.parentNode
        .querySelector('.c-tab-panel.is-active')
        .classList.remove('is-active')

      panel.classList.add('is-active')

      // Add is-active class to the target
      event.target.classList.add('is-active')
    }
  }
}

export default TabView
