import React from 'react'

const DeleteModal = ({ onClose, onSubmit, loading = false }) => {
  return (
    <>
      <div
        className="c-poll-modal-overlay c-poll-modal-overlay--full"
        onClick={onClose}
      />
      <div role="dialog" aria-modal="true" className="u-text-light c-poll-modal c-poll-modal--fixed">
        <div style={{ textAlign: 'center' }}>
          <p className="c-poll-modal__text u-text-medium">
            Are you sure you want to delete this comment?
          </p>
          <button
            className="c-button c-button--small c-button--quaternary c-poll-modal__button"
            type="button"
            style={{ opacity: 0.9 }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? 'Please wait...' : 'Confirm'}
          </button>

          <button
            className="c-button c-button--small c-button--quaternary c-poll-modal__button"
            type="button"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}

export default DeleteModal
