/**
 * Text Toggle
 */

class TextToggle {
  constructor($trigger) {
    this.trigger = $trigger
    this._init()
  }

  _init() {
    this.trigger.addEventListener('change', e => {
      this._toggleTextField(e.target.checked)
    })

    // Run this if item is pre-selected
    if (this.trigger.checked) {
      this._toggleTextField(true)
    }
  }

  /**
   * The trigger element contains a data-attribue called data-target.
   * The target represents the text field that needs to be toggled
   *
   * @param {boolean} isChecked
   */
  _toggleTextField(isChecked) {
    // Get the data attribute
    const target = this.trigger.dataset.target

    // Get the input wrapper
    const elem = document.getElementById(target)

    if (isChecked) {
      if (elem) {
        elem.classList.add('is-active')
      }
    } else {
      if (elem) {
        elem.classList.remove('is-active')
      }
    }
  }
}

export default TextToggle
