import React, { useState, useEffect } from 'react'
import ReactSlider from 'react-slider'

// Rating input
const Rating = ({
  options,
  initialAnswers,
  id,
  ratingCallback,
  type,
  onChange,
  required = false
}) => {
  const [initialValue, setInitialValue] = useState(null)

  const limit = parseInt(options.max || options['value.high'])
  const min = parseInt(options.min || options['value.low'])

  const likert = {
    max: 10,
    neutral: 5,
    min: 1
  }

  useEffect(() => {
    // Assign initial values
    const value = Array.isArray(initialAnswers)
      ? initialAnswers[initialAnswers.length - 1]
      : initialAnswers

    setInitialValue(value)
  }, [])

  useEffect(() => {
    ratingCallback(initialValue, id, type)
  }, [initialValue])

  const handleChange = (e, value) => {
    onChange(e)

    // If we have an initial value, and initial value is an object...
    // This is important because initial values are returned as an object
    // when retrieving data from API.
    if (
      initialValue &&
      typeof initialValue === 'object' &&
      typeof initialValue !== null
    ) {
      const newValue = {
        id: initialValue.id,
        questionId: initialValue.questionId,
        value: value.toString()
      }

      setInitialValue(newValue)
    } else {
      setInitialValue(value.toString())
    }
  }

  return (
    <div id={id} className="c-poll__results">
      <ReactSlider
        className="c-poll__bar"
        trackClassName="c-poll__bar"
        thumbClassName="c-poll__marker"
        min={likert.min}
        max={likert.max}
        marks
        onChange={value => handleChange(null, value)}
        value={initialValue && initialValue.value ? parseInt(initialValue.value || 0) : parseInt(initialValue || 0)}
        required={required}
      />

      <div className="c-poll__results-values">
        {limit ? (
          [...Array(limit)].map((x, i) => {
            const value = min + i
            return (
              <span className="c-poll__results-values-trigger" onClick={e => handleChange(e, value)} key={i}>
                {min + i <= limit && min + i}
              </span>
            )
          })
        ) : (
          <>
            <span className="c-poll__results-values-trigger" onClick={e => handleChange(e, likert.min)}>
              {options.min}
            </span>
            <span className="c-poll__results-values-trigger" onClick={e => handleChange(e, likert.neutral)}>
              {options.med ? options.med : 'Neutral'}
            </span>
            <span className="c-poll__results-values-trigger" onClick={e => handleChange(e, likert.max)}>
              {options.max}
            </span>
          </>
        )}
      </div>
    </div>
  )
}

export default Rating
