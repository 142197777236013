import React, { useState } from 'react'

const ReportModal = ({ closeModalCallback, reportedComment }) => {
  const [inputValue, setInputValue] = useState('')
  const [isReported, setIsReported] = useState(false)
  const handleReportSubmit = e => {
    e.preventDefault()

    axios
      .post(
        `/api/comments/${reportedComment.id}/reports`,
        {
          comment: reportedComment.text,
          reason: inputValue
        },
        {
          headers: {
            Authorization: `Bearer ${
              document.head.querySelector('meta[name="api-token"]').content
            }`
          }
        }
      )
      .then(response => {
        setIsReported(true)
        console.log('this is the response', response)
        window.location.reload()
      })
      .catch(err => {
        alert('There was a problem processing your request. Please try again.')
        console.error('Unable to post report', err)
      })
  }

  return (
    <>
      <div
        className="c-poll-modal-overlay c-poll-modal-overlay--full"
        onClick={() => closeModalCallback()}
      />
      <div role="dialog" aria-modal="true" className="u-text-light c-poll-modal c-poll-modal--fixed">
        {!isReported ? (
          <>
            <label
              className="c-poll-modal__text"
              htmlFor="report-comment-input"
            >
              Please enter a reason for reporting the following comment:
            </label>
            <p className="c-poll-modal__text u-text-medium">
              {reportedComment.text}
            </p>
            <form onSubmit={e => handleReportSubmit(e)}>
              <input
                id="report-comment-input"
                className="c-input c-input--full"
                type="text"
                placeholder="Reason for reporting..."
                required
                aria-required
                onChange={e => setInputValue(e.target.value)}
                value={inputValue}
              />
              <p className="c-poll-modal__text">
                The comment will be reviewed by one of our moderators.
              </p>
              <div className="c-button-wrap c-button-wrap--center">
                <button
                  className="c-button c-button--small c-button--quaternary c-poll-modal__button"
                  type="button"
                  onClick={() => closeModalCallback()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="c-button c-button--small c-button--quaternary c-poll-modal__button"
                >
                  Submit
                </button>
              </div>
            </form>
          </>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p className="c-poll-modal__text u-text-medium">
              Thank you for reporting this comment. It has been brought to the
              attention of our moderators.
            </p>
            <button
              className="c-button c-button--small c-button--quaternary c-poll-modal__button"
              type="button"
              onClick={() => closeModalCallback()}
            >
              Close
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default ReportModal
