import React from 'react'
import Pin from './Pin'

const Pins = ({ pins, triggerModalCallback }) =>
  pins.map((pin, i) => {
    const { x, y } = pin.comment && pin.comment.position
    return (
      <Pin
        key={i}
        top={y}
        left={x}
        handleClick={e => triggerModalCallback(e, pin)}
      />
    )
  })

export default Pins
