import React, { useState, useEffect } from 'react'

const { id } = window.LTN.user

const Rating = ({ comment, isResult, rating, commentId }) => {
  const [liked, setLiked] = useState(false)
  const [disliked, setDisliked] = useState(false)
  const [likes, setLikes] = useState(0)
  const [dislikes, setDislikes] = useState(0)
  const [score, setScore] = useState(0)

  useEffect(() => {
    setLikes(rating.likes)
    setDislikes(rating.dislikes)

    if (comment && comment.score) {
      setScore(comment.score)
    }
  }, [])

  const guest = {
    cursor: !id && 'default'
  }

  // Determines what action to take whern the the user clicks
  // the icon
  const setRating = ratingType => {
    if (!isResult && id) {
      if (
        (score === 1 && ratingType === 'like') ||
        (score === -1 && ratingType === 'dislike')
      ) {
        return deleteRating()
      }

      return postRating(ratingType)
    }

    console.info('Did not post reaction')
  }

  const deleteRating = () => {
    return axios
      .delete(`/api/comments/${commentId}/likes`, {
        headers: {
          Authorization: `Bearer ${
            document.head.querySelector('meta[name="api-token"]').content
          }`
        }
      })
      .then(() => {
        setLiked(false)
        setDisliked(false)
        setLikes(0)
        setDislikes(0)
        setScore(0)
      })
      .catch(err => {
        console.error('oops, unable to remove rating', err)
        alert(
          'Sorry there was a problem when posting your response. Please try again.'
        )
      })
  }

  const postData = score => {
    return axios
      .post(
        `/api/comments/${commentId}/likes`,
        {
          score
        },
        {
          headers: {
            Authorization: `Bearer ${
              document.head.querySelector('meta[name="api-token"]').content
            }`
          }
        }
      )
      .then(response => {
        const { likes, dislikes, score } = response.data.WallComment

        setLikes(likes)
        setDislikes(dislikes)
        setScore(score)
      })
      .catch(err => {
        console.error('Unable to post reaction due to error', err)
        alert(
          'Sorry there was a problem when posting your response. Please try again.'
        )
      })
  }

  const postRating = ratingType => {
    if (ratingType === 'like') {
      setLiked(!liked)
      setDisliked(false)

      const score = 1
      postData(score)
    } else {
      setDisliked(!disliked)
      setLiked(false)

      const score = -1
      postData(score)
    }
  }

  return (
    <div className="c-poll__thread-rating">
      <div className="c-poll__thread-rating--thumbs-up" style={guest}>
        <span className="sr-only"> Likes  </span>
        {likes}
        <button className="btn--like" aria-label="Like">
          <i
            className={`${liked || score === 1 ? 'fas' : 'far'} fa-thumbs-up`}
            onClick={() => setRating('like')}
          />
        </button>
      </div>
      |
      <div className="c-poll__thread-rating--thumbs-down" style={guest}>
        <span className="sr-only"> Dislikes  </span>
        {dislikes}
        <button className="btn--dislike" aria-label="Dislike">
          <i
            className={`${
              disliked || score === -1 ? 'fas' : 'far'
            } fa-thumbs-down`}
            onClick={() => setRating('dislike')}
          />
        </button>
      </div>
    </div>
  )
}

export default Rating
