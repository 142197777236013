import React, { useEffect, useState } from 'react'

// Single Line text input
const Text = ({ id, textCallback, type, onChange, initialAnswers, required = false }) => {
  const [initialValue, setInitialValue] = useState(null)

  useEffect(() => {
    const value = Array.isArray(initialAnswers)
      ? initialAnswers[initialAnswers.length - 1]
      : initialAnswers

    setInitialValue(value)
  }, [])

  useEffect(() => {
    const getValueToSend = () => {
      if (initialValue) {
        if (initialValue.id) {
          return initialValue
        } else {
          return initialValue.value
        }
      }
      return ''
    }

    const valueToSend = getValueToSend()

    textCallback(valueToSend, id, type)
  }, [initialValue])

  const handleChange = e => {
    onChange(e)

    if (initialValue) {
      const newValue = {
        id: initialValue.id,
        questionId: initialValue.questionId,
        value: e.target.value
      }

      setInitialValue(newValue)
    } else {
      setInitialValue({id: '', questionId: '', value: e.target.value})
    }
  }

  return (
    <input
      onChange={e => handleChange(e)}
      className="c-poll__input"
      type="text"
      id={id}
      defaultValue={initialValue ? initialValue.value : ''}
      placeholder="Answer"
      required={required}
    />
  )
}

export default Text
