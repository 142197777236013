/**
 * Handling the filtering and sorting of Categories
 * and Wards.
 */

//  CATEGORY FILTERING

export default function filtering() {
  const baseUrl = '/'
  const consultationsUrl = '/consultations'
  const params =
    window.location.search.length > 0
      ? window.location.search.substring(1).split('&')
      : []

  const categoryList = document.getElementById('consultation-categories')

  if (categoryList) {
    const categories = categoryList.getElementsByClassName('category')

    for (let i = 0; i < categories.length; i++) {
      if (
        window.location.search.indexOf(categories[i].getAttribute('name')) !==
        -1
      ) {
        categories[i].className += 'active-category'
      }
      categories[i].addEventListener('click', function() {
        let selectedCategory = document.getElementsByClassName(
          'active-category'
        )

        if (selectedCategory.length > 0) {
          selectedCategory[0].className = selectedCategory[0].className.replace(
            'active-category',
            ''
          )
        }

        const currentCategory = 'consultations[category]=' + this.getAttribute('name');

        this.className += ' active-category'
        if (params.length > 0) {
          if (params.findIndex(param => param.includes('category')) === -1) {
            params.push(currentCategory)
          }

          if (params.findIndex(param => param.includes('category')) > -1) {
            params.splice(
              params.findIndex(param => param.includes('category')),
              1
            )
            params.push(currentCategory)
          }
        } else {
          params.push(currentCategory)
        }

        params.join('&')
        window.location.href = params.length
          ? `${
              window.location.pathname === baseUrl ? baseUrl : consultationsUrl
            }?${params.join('&')}`
          : window.location.pathname === baseUrl
          ? baseUrl
          : consultationsUrl
      })
    }
  }

  // STATUS FILTERING
  const statusList = document.getElementById('consultation-statuses')

  if (statusList) {
    const statuses = statusList.getElementsByClassName('status')

    for (let i = 0; i < statuses.length; i++) {
      const status = statuses[i]
      const statusValue = status.dataset.value

      if (
        window.location.search.indexOf(statusValue) !== -1
      ) {
        status.className += 'active-ward'
      }
      status.addEventListener('click', function() {
        let selectedStatus = document.getElementsByClassName('active-status')

        if (selectedStatus.length > 0) {
          selectedStatus[0].className = selectedStatus[0].className.replace(
            'active-status',
            ''
          )
        }

        const currentStatus = 'consultations[status]=' + statusValue;

        this.className += ' active-status'
        if (params.length > 0) {
          if (params.findIndex(param => param.includes('status')) === -1) {
            params.push(currentStatus)
          }

          if (params.findIndex(param => param.includes('status')) > -1) {
            params.splice(
              params.findIndex(param => param.includes('status')),
              1
            )
            params.push(currentStatus)
          }
        } else {
          params.push(currentStatus)
        }

        params.join('&')
        window.location.href = params.length
          ? `${
              window.location.pathname === baseUrl ? baseUrl : consultationsUrl
            }?${params.join('&')}`
          : window.location.pathname === baseUrl
          ? baseUrl
          : consultationsUrl
      })
    }
  }


  // WARD FILTERING
  const wardList = document.getElementById('consultation-wards')

  if (wardList) {
    const wards = wardList.getElementsByClassName('ward')

    for (let i = 0; i < wards.length; i++) {
      if (
        window.location.search.indexOf(wards[i].getAttribute('name')) !== -1
      ) {
        wards[i].className += 'active-ward'
      }
      wards[i].addEventListener('click', function() {
        let selectedWard = document.getElementsByClassName('active-ward')

        if (selectedWard.length > 0) {
          selectedWard[0].className = selectedWard[0].className.replace(
            'active-ward',
            ''
          )
        }

        const currentWard = 'consultations[ward]=' + this.getAttribute('name');

        this.className += ' active-ward'
        if (params.length > 0) {
          if (params.findIndex(param => param.includes('ward')) === -1) {
            params.push(currentWard)
          }

          if (params.findIndex(param => param.includes('ward')) > -1) {
            params.splice(
              params.findIndex(param => param.includes('ward')),
              1
            )
            params.push(currentWard)
          }
        } else {
          params.push(currentWard)
        }

        params.join('&')
        window.location.href = params.length
          ? `${
              window.location.pathname === baseUrl ? baseUrl : consultationsUrl
            }?${params.join('&')}`
          : window.location.pathname === baseUrl
          ? baseUrl
          : consultationsUrl
      })
    }
  }
}
