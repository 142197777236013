import React from 'react'
import moment from 'moment'
const Body = ({
  scrollRef,
  title,
  createdAt,
  comment,
  description,
  children,
  modalActive
}) => (
  <div
    className="c-poll__body"
    style={{ overflow: modalActive && 'hidden' }}
    ref={scrollRef}
  >
    <div className="c-poll__meta">
      <div>
        <h3>{title}</h3>
        <small className="u-text-light">
          {moment(createdAt).format('Do MMMM YYYY')}
        </small>
      </div>
      <div>
        <small className="u-text-light">
          {comment.replies.length} comments
        </small>
      </div>
    </div>

    <p
      className="c-poll__text u-text-light"
      dangerouslySetInnerHTML={{ __html: description }}
    />

    {children}
  </div>
)

export default Body
