import React, { useState, useEffect } from 'react'
import localForage from 'localforage'

const IndividualAlert = ({ user, alertCallback, notification }) => {
  const [alertVisible, setAlertVisible] = useState(true)
  const hideAlert = () => {
    setAlertVisible(false)
  }

  const handleClose = () => {
    hideAlert()
    localForage.setItem('guest-notifications', false)
  }

  return (
    <div
      className="o-media c-alert"
      style={{
        position: 'absolute',
        opacity: alertVisible ? 1 : 0,
        right: alertVisible ? 0 : -510,
        transitionProperty: 'opacity, right',
        transition: '300ms linear',
        zIndex: 10,
      }}
    >
      <div className="c-alert__icon">
        <i className="fas fa-bell"></i>
        <div
          aria-hidden="true"
          className="c-alert__badge-counter"
          aria-label="Number of notifications: 1"
        >
          1
        </div>
      </div>
      <div className="c-alert__message">
        {notification && (
          <>
            <div className="c-alert__message-title">
              <p>{notification.title}</p>
            </div>
            <div className="c-alert__message-body">
              <p>{notification.message}</p>
            </div>
            <button className="c-alert__close" aria-label="Close alert pop-up">
              <i className="fas fa-times" onClick={() => hideAlert()}></i>
            </button>
          </>
        )}
        {!notification && !user && (
          <>
            <div className="c-alert__message-body">
              <a href="mailto:letstalk@newcastle.gov.uk.">
                Give us your feedback on this site here
              </a>{' '}
              <span className="c-alert__separator" aria-hidden="true">
                |
              </span>{' '}
              <button className="c-alert__close" onClick={handleClose}>
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const Alert = (props) => {
  const [guestNotifications, setGuestNotifications] = useState(null)
  const user = props.user

  useEffect(() => {
    localForage
      .getItem('guest-notifications')
      .then((val) => setGuestNotifications(val))
  }, [])

  if (!user && guestNotifications !== false) {
    return <IndividualAlert />
  }

  if (!props.receiveNotifications && user) {
    return null
  }

  if (user && props.receiveNotifications) {
    return notifications.map((notification, i) => (
      <div className="c-alert-wrapper">
        <IndividualAlert notification={notification} user={user} key={i} />
      </div>
    ))
  }

  return null
}

export default Alert
