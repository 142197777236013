import React, { useState, useEffect } from 'react'

// Dropdown input
const Dropdown = ({
  dropdownCallback,
  initialAnswers,
  value,
  id,
  options,
  type,
  onChange,
  required = false,
}) => {
  const [currentValue, setCurrentValue] = useState(null)

  const handleChange = (e) => {
    const { value } = e.target
    setCurrentValue(value)
    dropdownCallback(value, id, type)
    onChange(e)

    if (currentValue) {
      const newValue = {
        id: currentValue.id,
        questionId: currentValue.questionId,
        value: e.target.value,
      }

      setCurrentValue(newValue)
    } else {
      setCurrentValue(e.target.value)
    }
  }

  useEffect(() => {
    const value = Array.isArray(initialAnswers)
      ? initialAnswers[initialAnswers.length - 1]
      : initialAnswers

    setCurrentValue(value)
  }, [])

  return (
    <>
      <select
        value={currentValue ? currentValue.value : 'select'}
        id={id}
        className="c-poll__select"
        onChange={(e) => handleChange(e)}
        required={required}
      >
        <option value="select" disabled>
          Select an Option
        </option>
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  )
}

export default Dropdown
