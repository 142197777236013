import React, { useState } from 'react'
import Rating from './Rating'
import moment from 'moment'
import DeleteModal from './DeleteModal'
import UpdateModal from './UpdateModal'

const { id } = window.LTN.user

const Reply = ({ reply, handleReportModal }) => {
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [showEditModal, setEditModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const deleteReply = () => setDeleteModal(true)
  const editReply = () => setEditModal(true)

  const confirmDelete = () => {
    setLoading(true)

    return axios
      .delete(`/api/comments/${reply.id}`, {
        headers: {
          Authorization: `Bearer ${
            document.head.querySelector('meta[name="api-token"]').content
          }`
        }
      })
      .then(res => {
        if (res.status === 204) {
          window.location.reload()
        } else {
          setDeleteModal(false)
          alert('There was a problem deleting the comment. Please try again.')
        }
      })
      .catch(err => {
        console.error('this is the err', err)
        setDeleteModal(false)
        alert('There was a problem deleting the comment. Please try again.')
      })
  }

  const performUpdate = text => {
    setLoading(true)

    return axios
      .put(
        `/api/comments/${reply.id}`,
        { text },
        {
          headers: {
            Authorization: `Bearer ${
              document.head.querySelector('meta[name="api-token"]').content
            }`
          }
        }
      )
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        } else {
          console.error('this is the err', err)
          setEditModal(false)
          alert('There was a problem updating the comment. Please try again.')
        }
      })
      .catch(err => {
        console.error('this is the err', err)
        setEditModal(false)
        alert('There was a problem updating the comment. Please try again.')
      })
  }

  return (
    <div className="c-poll__thread c-poll__thread--right">
      <div className="o-media__body">
        <div className="c-poll__thread-message c-poll__thread-message--secondary">
          <p>{reply.text}</p>
          <ul
            className="o-list o-list--inline o-list--bordered o-list--black-border c-poll__thread-message-metric"
            style={{ justifyContent: 'flex-end', alignItems: 'center' }}
          >
            <li className="o-list__item">
              <Rating
                rating={{ likes: reply.likes, dislikes: reply.dislikes }}
                commentId={reply.id}
              />
            </li>

            {id && reply.author.id !== id && (
              <li className="o-list__item">
                <button
                  className="c-button c-button--link"
                  type="button"
                  onClick={() => handleReportModal(reply)}
                >
                  Report
                </button>
              </li>
            )}

            {reply.author.id === id && (
              <li className="o-list__item">
                <button
                  className="c-button c-button--link"
                  type="button"
                  onClick={editReply}
                >
                  Edit
                </button>
              </li>
            )}

            {reply.author.id === id && (
              <li className="o-list__item">
                <button
                  className="c-button c-button--link"
                  type="button"
                  onClick={deleteReply}
                >
                  Delete
                </button>
              </li>
            )}
          </ul>
        </div>

        {showDeleteModal && (
          <DeleteModal
            onClose={loading ? () => {} : () => setDeleteModal(false)}
            onSubmit={confirmDelete}
            loading={loading}
          />
        )}

        {showEditModal && (
          <UpdateModal
            comment={reply}
            onClose={loading ? () => {} : () => setEditModal(false)}
            onSubmit={performUpdate}
            loading={loading}
          />
        )}

        <div className="c-poll__thread--author-metrics u-text-light">
          <p>
            {moment(reply.postedAt).format('hh:mma')} - {reply.author.name.full}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Reply
