import React, { useEffect, useState } from 'react'

const UpdateModal = ({ comment, onClose, onSubmit, loading = false }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(comment.text)
  }, [comment])

  const handleChange = event => {
    setValue(event.target.value)
  }

  return (
    <>
      <div
        className="c-poll-modal-overlay c-poll-modal-overlay--full"
        onClick={onClose}
      />
      <div role="dialog" aria-modal="true" className="u-text-light c-poll-modal c-poll-modal--fixed">
        <label
          className="c-poll-modal__text"
          htmlFor="edit-comment-input"
          style={{ marginBottom: 12, display: 'block' }}
        >
          Please enter your new comment:
        </label>
        <form
          onSubmit={event => {
            event.preventDefault()
            onSubmit(value)
          }}
        >
          <textarea
            id="edit-comment-input"
            className="c-input c-input--full"
            type="text"
            placeholder="Your new comment"
            required
            onChange={handleChange}
            value={value}
          />
          <p className="c-poll-modal__text">
            The comment will be reviewed by one of our moderators.
          </p>
          <div className="c-button-wrap c-button-wrap--center">
            <button
              className="c-button c-button--small c-button--quaternary c-poll-modal__button"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="c-button c-button--small c-button--quaternary c-poll-modal__button"
              disabled={loading}
            >
              {loading ? 'Please wait...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default UpdateModal
