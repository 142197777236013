import React, { useEffect, useState } from 'react'

// Multiline text input
const Textarea = ({ initialAnswers, id, textareaCallback, type, onChange, required = false }) => {
  const [initialValue, setInitialValue] = useState(null)

  useEffect(() => {
    const value = Array.isArray(initialAnswers)
      ? initialAnswers[initialAnswers.length - 1]
      : initialAnswers

    setInitialValue(value)
  }, [])

  useEffect(() => {
    const getValueToSend = () => {
      if (initialValue) {
        if (initialValue.id) {
          return initialValue
        } else {
          return initialValue.value
        }
      }
      return ''
    }

    const valueToSend = getValueToSend()

    textareaCallback(valueToSend, id, type)
  }, [initialValue])

  const handleChange = e => {
    onChange(e)

    if (initialValue) {
      const newValue = {
        id: initialValue.id,
        questionId: initialValue.questionId,
        value: e.target.value
      }

      setInitialValue(newValue)
    } else {
      setInitialValue(e.target.value)
    }
  }
  // const handleChange = e => {
  //   const { value } = e.target
  //   textareaCallback(value, id, type)
  //   onChange(e)
  // }

  return (
    <textarea
      className="c-poll__input c-poll__input--textarea"
      rows={3}
      placeholder="Answer"
      onChange={e => handleChange(e)}
      defaultValue={initialValue ? initialValue.value : ''}
      id={id}
      required={required}
    ></textarea>
  )
}

export default Textarea
