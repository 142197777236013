import React from 'react'
import Slider from 'react-slick'

const Carousel = props => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000
  }

  return (
    <Slider {...settings} className="carousel">
      {props.slides && props.slides.map(slide => slide.items.map(item => {
        let child = null;
        if (item.type === 'image') {
          child = <img alt={item.content} className='carousel__image' src={item.attachment} />
        }

        if (item.type === 'video') {
          child = (
            <video className='carousel__video' controls preload='metadata'>
              <source src={item.attachment} type='video/mp4' />
              {item.subtitles && <track label='English' kind='subtitles' srcLang='en' src={item.subtitles} />}
            </video>
          )
        }

        return (
          <div key={item.id}>
            {slide.url ? <a href={slide.url}>{child}</a> : child}
          </div>
        )
      }))}
    </Slider>
  )
}

export default Carousel
