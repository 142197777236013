import React, { useState } from 'react'
import classnames from 'classnames'

const ShareModal = props => {
  const { resource, route } = props
  const [modalActive, setIsModalActive] = useState(false)
  return (
    <>
      <button
        type="button"
        onClick={() => setIsModalActive(true)}
        className={classnames(
          'c-article__share c-button c-button--primary u-text-medium',
          {
            'c-button--tertiary': resource === 'event'
          }
        )}
      >
        Share{' '}
        {(resource == 'wall' || resource == 'poll' || resource == 'survey') &&
          resource}

        <i className="c-article__share-icon fas fa-share-alt"></i>
      </button>

      {modalActive && (
        <div className="c-share-modal-wrapper">
          <div aria-labelid="dialog-title" role="dialog" aria-modal="true" className="c-share-modal">
            <div class="c-share-modal__close__container">
              <button className="c-share-modal__close-btn" onClick={() => setIsModalActive(false)} aria-label='close'>
                <i aria-hidden="true"> x </i>
              </button>
            </div>
            <h2 id="dialog-title" className="c-share-modal__title">
              Share this {resource} to get others involved:
            </h2>

            <ul className="o-list o-list--inline c-share-modal__button-group">
              <li className="o-list__item " aria-label="Share on Twitter">
                <a
                  href={`${route}?network=twitter`}
                  rel="noreferrer noopener"
                  target="_blank"
                  className="c-share-modal__button c-button c-button--secondary c-button--small"
                >
                  Twitter
                  <i className="fab fa-twitter c-share-modal__icon"></i>
                </a>
              </li>
              <li className="o-list__item " aria-label="Share on Facebook">
                <a
                  href={`${route}?network=facebook`}
                  rel="noreferrer noopener"
                  target="_blank"
                  className="c-share-modal__button c-button c-button--secondary c-button--small"
                >
                  Facebook
                  <i className="fab fa-facebook-f c-share-modal__icon"></i>
                </a>
              </li>
              <li className="o-list__item " aria-label="Share on LinkedIn">
                <a
                  href={`${route}?network=linkedin`}
                  rel="noreferrer noopener"
                  target="_blank"
                  className="c-share-modal__button c-button c-button--secondary c-button--small"
                >
                  LinkedIn
                  <i className="fab fa-linkedin-in c-share-modal__icon"></i>
                </a>
              </li>
            </ul>
          </div>
          <div
            className="c-share-modal-backdrop"
            onClick={() => setIsModalActive(false)}
          ></div>
        </div>
      )}
    </>
  )
}

export default ShareModal
