import React, { useState, useEffect } from 'react'
import Modal from '../Wall/Comments/Modal'
import Pins from './Pins'
import ImageOverlay from '../Wall/ImageOverlay'

// Image Aspect Component

const ImageAspect = ({ consultationActive = true, comments, imageUrl, id, ...props }) => {
  const [modalActive, setModalActive] = useState(false)
  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 })
  const [activePin, setActivePin] = useState({})
  const [isNewPin, setIsNewPin] = useState(false)
  const [pins, setPins] = useState([])

  useEffect(() => {
    comments &&
      setPins(
        comments
          .filter(comment => !comment.hasReports)
          .map(comment => ({
            comment
          }))
      )
  }, [])

  const handleMouseMove = event => {
    setMouseCoords({
      x: event.nativeEvent.offsetX,
      y: event.nativeEvent.offsetY
    })
  }

  const handleNewPin = modalContent => {
    if (window.LTN.user.id && consultationActive) {
      setIsNewPin(true)
      setModalActive(true)

      if (modalContent) {
        const newPin = {
          comment: {
            text: modalContent.text,
            author: modalContent.author,
            position: {
              x: modalContent.position.x,
              y: modalContent.position.y
            },
            replies: [],
            postedAt: new Date().now
          }
        }
        setPins([...pins, newPin])
        setIsNewPin(false)
      }
    }
  }

  const updateReplies = replies => {
    activePin.comment
      ? (activePin.comment.replies = replies)
      : (activePin.replies = replies)
  }

  const handleModalClose = () => {
    setModalActive(false)
    setIsNewPin(false)
  }

  return (
    <div className="c-wall">
      <Pins
        pins={pins}
        triggerModalCallback={(e, pin) => {
          setModalActive(true)
          setActivePin(pin)
        }}
      />

      {modalActive && (
        <>
          <Modal
            wallId={id}
            updateReplies={replies => updateReplies(replies)}
            content={isNewPin ? {} : activePin}
            handleNewPin={modalContent => {
              handleNewPin(modalContent)
              setPins([...pins, { comment: modalContent }])
            }}
            position={mouseCoords}
            handleModalClose={() => handleModalClose()}
          />
          <ImageOverlay handleClick={() => handleModalClose()} />
        </>
      )}

      <img
        id="image-survey-image"
        alt=""
        onMouseMove={handleMouseMove}
        onClick={() => handleNewPin()}
        className="c-article__media-media"
        src={imageUrl}
      />
    </div>
  )
}

export default ImageAspect
