import React from 'react'
import { render } from 'react-dom'

let context = require.context('./components', true, /\.(\/index)?js$/)

export const sprinkle = () => {
  const nodes = document.querySelectorAll('[data-react]')

  for (const node of nodes) {
    const regexp = new RegExp(node.dataset.react + '(/index)?\\.js')
    const match = context.keys().find(path => path.match(regexp))
    const Comp = context(match).default
    const props = node.dataset.props ? JSON.parse(node.dataset.props) : {}

    props.children = node.innerHTML

    render(<Comp {...props} />, node)
  }
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios')
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]')
if (token) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
  console.error(
    'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token'
  )
}
