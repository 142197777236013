import React, { useState } from 'react'

const CommentInput = ({ handleSubmitCallback, placeholder }) => {
  const [comment, setComment] = useState('')
  const handleSubmit = e => {
    e.preventDefault()
    comment.length > 0 && handleSubmitCallback(comment)
    setComment('')
  }

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <div
        className="c-wall__input-group c-wall__modal-footer"
        style={{ display: 'flex' }}
      >
        <label aria-label="Comment to start a discussion"></label>
        <input
          onChange={e => setComment(e.target.value)}
          value={comment}
          type="text"
          placeholder={placeholder}
          className="c-input c-input--minimal c-wall__input"
          rows={1}
        />
        <button className="c-button c-button--primary">Comment</button>
      </div>
    </form>
  )
}

export default CommentInput
