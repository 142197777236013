import React, { useState } from 'react'
import Comment from './Comment'

const Comments = ({ scrollRef, comments, loading, isResult }) => {
  return (
    <>
      {loading && <p className="c-wall__loading">Getting Comments...</p>}
      <ul
        ref={scrollRef}
        style={{ padding: comments.length == 0 && 0 }}
        className="c-wall__comment-list"
      >
        {comments &&
          comments.length > 0 &&
          comments
            .filter(comment => !comment.hasReports)
            .map((comment, i) => (
              <li key={comment.id}>
                <Comment comment={comment} isResult={isResult} />
              </li>
            ))}
      </ul>
    </>
  )
}

export default Comments
