import React from 'react'

const CompletionModal = ({ referenceId, setCompletionModal }) => {
  const handleClose = () => {
    setCompletionModal(false)
  }

  const completion = window.LTN.user.profileCompletion || 0;

  return (
    <>
      <div className="c-prompt-modal-overlay" />
      <div className="c-prompt-modal">
        {
          completion < 90 ? (
            <p className="c-prompt-modal__text">
              Thank you for taking part. You are helping to create a better city.
            </p>
          )
            : (
              <>
                <p className="c-prompt-modal__text">
                  Thank you for taking part. Please consider completing the rest of your profile.
                </p>
                <div className="c-button-wrap c-button-wrap--center">
                  <button
                    type="button"
                    className="c-button c-button--small c-button--primary c-prompt-modal__button"
                    onClick={() => handleClose()}
                  >
                    No Thanks
                  </button>
                  <a
                    className="c-button c-button--small c-button--secondary c-prompt-modal__button"
                    href={`/profile/edit?user=${window.LTN.user.id}`}
                  >
                    Update Profile
                  </a>
                </div>
              </>
            )
        }
      </div>
    </>
  )
}

export default CompletionModal
