import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'

import CommentField from './CommentField'
import ReportModal from './ReportModal'
import Reply from './Reply'
import Rating from './Rating'
import Body from './Body'
import Header from './Header'

const axios = require('axios')
const { id } = window.LTN.user

const TopicWall = props => {
  const [comment, setComment] = useState()
  const [commentDislikes, setCommentDislikes] = useState(0)
  const [commentLikes, setCommentLikes] = useState(0)
  const [reportModal, setReportModal] = useState(false)
  const [reportedComment, setReportedComment] = useState({})
  const [useTitle, setUseTitle] = useState(false)
  const [replies, setReplies] = useState({})
  const [error, setError] = useState()

  useEffect(() => {
    setComment(props.comments[0])

    if (document.getElementById('popular-wrap')) {
      setUseTitle(true)
    }
  }, [])

  useEffect(() => {
    setCommentLikes((props.comments.length && props.comments[0].likes) || 0)
    setCommentDislikes(
      (props.comments.length && props.comments[0].dislikes) || 0
    )
    setReplies(props.comments.length && props.comments[0].replies)
  }, [])

  const handleSubmit = (e, payload) => {
    e.preventDefault()

    payload.text.length > 0 &&
      axios
        .post(
          `/api/walls/${props.id}/comments`,
          { text: payload.text },
          {
            headers: {
              Authorization: `Bearer ${
                document.head.querySelector('meta[name="api-token"]').content
              }`
            }
          }
        )
        .then(res => {
          setReplies([...replies, res.data.WallComment])
          scrollToBottom()
        })
        .catch(function(err) {
          if (err.response) {
            const { data } = err.response

            if (data.message === 'Foul language warning') {
              alert('Foul language detected. Please avoid using vulgar terms.')
            } else {
              alert(data.message)
            }
          }

          console.error('Unable to process comment', {
            status: err.response.status,
            statusText: err.response.statusText
          })
        })
  }

  const handleReportModal = reply => {
    setReportedComment(reply)
    setReportModal(true)
  }

  const scrollRef = useRef(null)

  const scrollToBottom = () => {
    scrollRef.current.scrollTop = 100 + scrollRef.current.scrollHeight
  }

  return (
    <>
      {comment && (
        <div className="c-poll c-poll--topic c-poll--logged-in">
          <Header
            type={props.type}
            category={props.category}
            title={useTitle ? props.consultationTitle : null}
          />
          <Body
            comment={comment}
            description={props.description}
            createdAt={props.createdAt}
            title={props.title}
            scrollRef={scrollRef}
            modalActive={reportModal}
          >
            <div className="c-poll__thread">
              <div className="o-media">
                <div className="o-media__figure">
                  <img
                    className="o-media__figure c-avatar"
                    src={`${comment.author.avatarUrl ||
                      'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}`}
                    alt={`Photo of ${comment.author.name.full}`}
                  />
                </div>
                <div>
                  <div className="o-media__body c-poll__thread-message">
                    <div>
                      <p>{comment.text}</p>
                      <div className="c-poll__thread-message-metric">
                        <Rating
                          comment={comment}
                          isResult={props.isResult}
                          rating={{
                            likes: commentLikes || comment.likes,
                            dislikes: commentDislikes || comment.dislikes
                          }}
                          updateDislikes={value => setCommentDislikes(value)}
                          updateLikes={value => setCommentLikes(value)}
                          commentId={comment.id}
                        />
                      </div>
                    </div>
                    {!id && (

                        <div>
                           <div className="c-poll__register-container">
                            <div>
                              <a href="/login">Login</a>
                            </div>
                            <span  aria-hidden="true" className="c-poll__separator">|{' '}</span>
                            <div>
                              <a href="/register">Register </a>
                            </div>
                          </div>
                          to get involved in
                            discussion
                        </div>

                    )}
                  </div>
                  <div className="c-poll__thread--author-metrics u-text-light">
                    <p style={{ textAlign: 'left' }}>
                      {moment(comment.postedAt).format('hh:mma')} -{' '}
                      {comment.author.name.full}
                    </p>
                  </div>
                </div>
              </div>

              {replies &&
                replies
                  .filter(reply => !reply.hasReports)
                  .map((reply, i) => (
                    <Reply
                      handleReportModal={handleReportModal}
                      reply={reply}
                      key={i}
                    />
                  ))}
            </div>
          </Body>
          {reportModal && (
            <ReportModal
              closeModalCallback={() => setReportModal(false)}
              reportedComment={reportedComment}
            />
          )}
          <CommentField
            isResult={props.isResult}
            submitCallback={(e, payload) => handleSubmit(e, payload)}
          />
        </div>
      )}
    </>
  )
}

export default TopicWall
