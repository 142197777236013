export default function() {
  const modal = document.getElementById('account-deactivation-modal-js')
  document
    .getElementById('trigger-deactivate-modal-js')
    .addEventListener('click', () => {
      modal.style.display = 'block'
    })

  document
    .getElementById('close-deactivate-modal-js')
    .addEventListener('click', () => {
      modal.style.display = 'none'
    })
}
