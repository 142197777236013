import React, { useState, useEffect } from 'react'
import {
  GoogleMapProvider,
  MapBox,
  Marker,
  InfoWindow
} from '@googlemap-react/core'
import Modal from '../Wall/Comments/Modal'
import ImageOverlay from '../Wall/ImageOverlay'
import { filter } from 'lodash'

const Map = ({ consultationActive = true, ...props }) => {
  const { comments } = props
  const [modalActive, setModalActive] = useState(false)
  const [pinCoords, setPinCoords] = useState({})
  const [activePin, setActivePin] = useState({})
  const [isNewPin, setIsNewPin] = useState(false)
  const [pins, setPins] = useState([])
  const [key, setKey] = useState(Math.random())

  useEffect(() => {
    const filteredComments = [];
    if (comments) {
      comments.forEach(comment => {
        console.log({ comment });
        filteredComments.push({ comment });
      });
    }
    setPins(filteredComments);
  }, [])

  const handleSetPinCoords = e => {
    setPinCoords({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    })
  }

  const handleNewPin = modalContent => {
    if (window.LTN.user.id && consultationActive) {
      setIsNewPin(true)
      setModalActive(true)

      if (modalContent) {
        const newPin = {
          comment: {
            text: modalContent.text,
            author: modalContent.author,
            position: { lat: pinCoords.lat, lng: pinCoords.lng },
            replies: [],
            postedAt: new Date().now,
            id: modalContent.id
          }
        }

        setPins([...pins, newPin])
        setActivePin(newPin)
        setIsNewPin(false)
        setKey(Math.random())
      }
    }
  }

  const updateReplies = replies => {
    activePin.comment
      ? (activePin.comment.replies = replies)
      : (activePin.replies = replies)
  }

  const handleModalClose = () => {
    setModalActive(false)
    setActivePin({})
    setIsNewPin(false)
  }

  return (
    <div
      className="c-wall c-wall--map"
      style={{ position: 'relative' }}
      id="map-survey-map"
      key={key}
    >
      <GoogleMapProvider>
        <MapBox
          apiKey="AIzaSyC43SdCb1jFvFM0Fx6R2GtVexGOI3gUUnE"
          opts={{
            center: {
              lat: props.position.lat,
              lng: props.position.lng
            },
            zoom: props.position.zoom,
            zoomControl: true,
            dragControl: true,
            fullscreenControl: false,
            streetViewControl: false,
            tiltControl: false,
            rotateControl: false,
            mapTypeControl: false,
            styles: [
              {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }]
              },
              {
                featureType: 'poi.attraction',
                stylers: [{ visibility: 'on' }]
              },
              {
                featureType: 'transit',
                stylers: [{ visibility: 'off' }]
              }
            ],
            scrollwheel: true,
            draggable: true
          }}
          className="c-article__media-media"
          onClick={e => {
            handleSetPinCoords(e)
            handleNewPin()
          }}
        />
        {pins.map((pin, i) => {
          return (
            <>
              <Marker
                key={Math.random()}
                id={`marker-${pin.comment && pin.comment.id ? pin.comment.id : Math.random()
                  }`}
                opts={{
                  position: pin.comment ? pin.comment.position : pin.position
                }}
                onClick={() => {
                  setActivePin(pin)
                  setModalActive(true)
                }}
              />

              <InfoWindow
                key={Math.random()}
                visible
                anchorId={`marker-${pin.comment && pin.comment.id ? pin.comment.id : Math.random()
                  }`}
                opts={{
                  content: pin.comment && pin.comment.text
                }}
              />
            </>
          )
        })}

        {modalActive && (
          <>
            <Modal
              wallId={props.id}
              isResult={props.isResult}
              updateReplies={replies => updateReplies(replies)}
              content={isNewPin ? {} : activePin}
              handleNewPin={modalContent => {
                handleNewPin(modalContent)
                // setPins([...pins, modalContent])
              }}
              position={pinCoords}
              handleModalClose={() => handleModalClose()}
              allowInteractions={consultationActive}
            />
            <ImageOverlay handleClick={() => handleModalClose()} />
          </>
        )}
      </GoogleMapProvider>
    </div>
  )
}
export default Map
