// Credit https://github.com/rajeshpillai/youtube-react-calendar/blob/master/src/Components/Calendar/index.js

import React from 'react'
import moment from 'moment'
import classnames from 'classnames'

export default class Calendar extends React.Component {
  state = {
    dateContext: moment(),
    today: moment(),
    showMonthPopup: false,
    showYearPopup: false,
    selectedDay: null,
    data: {}
  }

  weekdays = moment.weekdays()
  weekdaysShort = moment.weekdaysShort()
  months = moment.months()

  year = () => this.state.dateContext.format('Y')
  month = () => this.state.dateContext.format('MMMM')
  daysInMonth = () => this.state.dateContext.daysInMonth()
  currentDate = () => this.state.dateContext.get('date')
  currentDay = () => this.state.dateContext.format('D')

  firstDayOfMonth = () => {
    let dateContext = this.state.dateContext
    let firstDay = moment(dateContext)
      .startOf('month')
      .format('d')
    return firstDay
  }

  componentDidMount() {
    this.setState({
      currentMonth: this.month(),
      currentMonthNumber: this.state.dateContext.format('MM'),
      data: { ...this.setEventDays(this.month()) }
    })
  }

  /**
   * Set the highlighted days within the calendar. This is driven by
   * dates returned from the JSON response.
   */

  setEventDays = month => {
    const events = this.props.initialProps[month.toLowerCase()]
    if (events) {
      return {
        eventDates: events.map(event => {
          return {
            startDate: moment(event.startDate).format('D'),
            endDate: moment(event.endDate).format('D'),
            startMonth: moment(event.startDate).format('MM'),
            endMonth: moment(event.endDate).format('MM')
          }
        })
      }
    }
  }

  setMonth = month => {
    let monthNo = this.months.indexOf(month)
    let dateContext = Object.assign({}, this.state.dateContext)
    dateContext = moment(dateContext).set('month', monthNo)

    this.setState({
      dateContext: dateContext,
      currentMonth: month,
      currentMonthNumber: moment()
        .month(month)
        .format('MM'),
      data: { ...this.setEventDays(month) }
    })
  }

  onSelectChange = (e, data) => {
    this.setMonth(data)
    this.props.onMonthChange && this.props.onMonthChange()
  }

  /**
   * Render Weekdays
   * Display the weekdays above the calendar i.e. Sun, Mon, Tue etc...
   */
  renderWeekdays = () => {
    return this.weekdaysShort.map(day => {
      return (
        <td key={day} className="week-day">
          {day}
        </td>
      )
    })
  }

  /**
   * Render Months
   *
   */
  renderMonths = () => {
    return this.months.map(month => {
      return (
        <div key={month}>
          <p
            onClick={e => this.onSelectChange(e, month)}
            className={classnames('c-calendar__month', {
              'is-active': this.state.currentMonth === month
            })}
          >
            {month}
          </p>
        </div>
      )
    })
  }

  /**
   * Render Blanks
   */
  renderBlanks = () => {
    let blanks = []

    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(
        <td key={i * 80} className="emptySlot">
          {''}
        </td>
      )
    }

    return blanks
  }

  renderDaysInMonth = () => {
    let daysInMonth = []

    for (let d = 1; d <= this.daysInMonth(); d++) {
      const className = d == this.currentDay() ? 'day current-day' : 'day'

      const {
        data: { eventDates }
      } = this.state

      const selectedClass =
        eventDates &&
        eventDates.length > 0 &&
        eventDates.map(eventDate => {
          // We need a function to handle this logic
          const shouldSelectDay = day => {
            const { startMonth, endMonth, startDate, endDate } = eventDate

            // Determine if this is the start day
            const isStartDay = day >= parseInt(startDate)

            // Determine if this is the end day
            const isEndDay = day <= parseInt(endDate)

            // Determine if selected month is the same as the end month
            const isInSameMonth =
              parseInt(endMonth) === parseInt(this.state.currentMonthNumber)

            return (isStartDay && isEndDay) ||
              (!isInSameMonth && day > parseInt(startDate)) ||
              (isInSameMonth && startMonth !== endMonth && isEndDay)
              ? 'selected-day'
              : ''
          }

          return shouldSelectDay(d)
        })

      daysInMonth.push(
        <td key={d} className={classnames([className, selectedClass])}>
          <span
            onClick={e => {
              this.onDayClick(e, d)
            }}
          >
            {d}
          </span>
        </td>
      )
    }

    return daysInMonth
  }

  render() {
    const Weekdays = this.renderWeekdays()
    const Months = this.renderMonths()
    const totalSlots = [...this.renderBlanks(), ...this.renderDaysInMonth()]

    let eventData = []
    if (this.state.currentMonth) {
      eventData = this.props.initialProps[this.state.currentMonth.toLowerCase()]
    }

    let rows = []
    let cells = []

    /**
     * Build up Rows on Render
     */
    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row)
      } else {
        let insertRow = cells.slice()
        rows.push(insertRow)
        cells = []
        cells.push(row)
      }
      if (i === totalSlots.length - 1) {
        let insertRow = cells.slice()
        rows.push(insertRow)
      }
    })

    const Rows = rows.map((d, i) => {
      return (
        <tr className="c-calendar__day" key={i * 100}>
          {d}
        </tr>
      )
    })

    return (
      <>
        <div className="c-calendar-days">{Months}</div>
        <div className="c-calendar-wrap">
          <div
            className="o-layout o-layout--1-col-phone o-layout--2-col-tablet o-layout--2-col-desktop"
            style={{ alignItems: 'center' }}
          >
            <div style={{ height: '100%' }}>
              <div className="c-poll c-poll--spaced c-poll--border-blue">
                <div className="c-poll__header">
                  <h3 className="c-poll__header-title">
                    Events {this.state.currentMonth} -{' '}
                    {eventData.length
                      ? moment(eventData[0].startDate).format('YYYY')
                      : this.year()}
                  </h3>
                </div>
                {eventData && eventData.length > 0 ? (
                  eventData.map((event, i) => (
                    <div key={i} className="c-poll__body">
                      <div className="o-media o-media--vertical-mobile">
                        <img
                          className="o-media__figure c-calendar__event-image"
                          src="/images/police.jpg"
                          alt="Photo of James Mason"
                        />
                        <div className="o-media__body">
                          <div className="c-poll__event">
                            <p className="u-text-light">
                              <b>{event.title}</b>
                              <br />
                              {event.venue}
                              <br />
                              {moment(event.startDate).format('Do MMMM')} -{' '}
                              {moment(event.endDate).format('Do MMMM')}
                            </p>

                            <a
                              className="c-poll__link"
                              href={`/events/${event.slug}`}
                            >
                              Read More >
                            </a>
                          </div>

                          <div className="c-poll__event">
                            <p className="u-text-light">
                              <b>{eventData.event_type}</b>
                              <br />
                            </p>
                            <div
                              className="u-text-light"
                              dangerouslySetInnerHTML={{
                                __html: eventData.description
                              }}
                            />
                          </div>

                          <div className="c-poll__event">
                            {eventData.category && (
                              <p className="u-text-tag u-text-tag--blue">
                                {eventData.category}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="c-calendar__message">
                    <p>No events this month</p>
                  </div>
                )}
              </div>
            </div>

            <div>
              <div className="calendar-container">
                <span>Calendar</span>
                <table className="c-calendar">
                  <thead>
                    <tr scope="col">
                    {Weekdays}
                    </tr>
                  </thead>
                  <tbody>{Rows}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
