import React, { useState, useEffect } from 'react'

const Input = ({ checked, onChange, optionValue, name, required = false }) => {
  const [value, setValue] = useState('')
  const [internalChecked, setInternalChecked] = useState(false)

  useEffect(() => {
    setValue(optionValue)
  }, [optionValue])

  useEffect(() => {
    setInternalChecked(checked)
  }, [checked])

  const handleChange = event => {
    setInternalChecked(!internalChecked)
    onChange(event)
  }

  return (
    <input
      checked={internalChecked}
      onChange={handleChange}
      className="c-input--radio"
      type="radio"
      value={value}
      name={name}
      required={required}
    />
  )
}

// Single Option Selection
const Radio = ({ radioCallback, id, options, value, type, onChange, required = false }) => {
  const [currentValue, setCurrentValue] = useState('')

  const handleRadio = e => {
    const { value } = e.target

    setCurrentValue(value)
    radioCallback(value, id, type)
    onChange(e)
  }

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  return (
    <fieldset id={id} className="c-poll__selection c-poll__selection--survey">
      {options.map((option, i) => (
        <label key={i}>
          <div className="c-poll__option">
            <Input
              checked={
                currentValue && currentValue.value
                  ? option.value === currentValue.value
                  : option.value === currentValue
              }
              onChange={handleRadio}
              optionValue={option.value}
              name={`radio-group-${id}`}
              required={required}
            />

            <p>{option.label}</p>
          </div>
        </label>
      ))}
    </fieldset>
  )
}

export default Radio
