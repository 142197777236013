import React from "react";
const Pin = ({ top, left, handleClick }) => (
  <div>
    <button
      onClick={() => {
        handleClick();
      }}
      className="c-wall__pin"
      aria-label="Click to contribute to an existing discussion"
      style={{
        display: !top && !left && "none",
        top: top && `${top}%`,
        left: left && `${left}%`,
        transform: "translateX(-12px) translateY(-28px)"
      }}
    />
  </div>
);

export default Pin;
