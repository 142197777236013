import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import MarkerClusterer from '@google/markerclusterer'

export default class GoogleMapContainer extends Component {
  setGoogleMapRef(map, maps) {
    this.googleMapRef = map
    this.googleRef = maps

    const { consultations } = this.props

    const plotDefaultMarker = () => {
      const point = {
        lat: 54.9786638,
        lng: -1.6101248
      }

      const marker = new this.googleRef.Marker({ position: point })
      const contentString = `
        <div style="padding: 8px;">
          <h2 style="margin-bottom: 8px;">Newcastle Wide Consultations</h2>
          <a style="color: #9A1462;" href="/consultations?ward=none">View Consultations</a>
        </div>
      `

      let infowindow = new this.googleRef.InfoWindow({
        content: contentString,
        disableAutoPan: true
      })

      marker.addListener('click', function() {
        infowindow.open(map, marker)
      })

      infowindow.open(map, marker)

      return marker
    }

    // Then plot dynamic markers, these are based on lng/lat values
    // from the database.
    let locations = consultations.data
      .filter(consultation => {
        return (
          consultation.position &&
          consultation.position.lat !== 54.9786638 &&
          consultation.position.lng !== -1.6101248
        )
      })
      .map(consultation => consultation)

    let markers =
      locations &&
      locations.map((location, index) => {
        const point = {
          lng: location.position.lng,
          lat: location.position.lat
        }

        const marker = new this.googleRef.Marker({ position: point })
        const contentString = `
          <div style="padding: 8px;">
            <h2 style="margin-bottom: 8px;">${location.title} - ${location.summary}</h2>
            <a style="color: #A32656;" href="/consultations/${location.id}/participate">Participate</a>
          </div>
        `

        let infowindow = new this.googleRef.InfoWindow({
          content: contentString
        })

        marker.addListener('click', function() {
          infowindow.open(map, marker)
        })

        return marker
      })

    let markerCluster = new MarkerClusterer(
      map,
      [...markers, plotDefaultMarker()],
      {
        imagePath:
          'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        gridSize: 120,
        minimumClusterSize: 2
      }
    )
  }

  render() {
    return (
      <div style={{ height: 600, width: '100%', marginBottom: 32 }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `AIzaSyC43SdCb1jFvFM0Fx6R2GtVexGOI3gUUnE` }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.setGoogleMapRef(map, maps)}
          defaultCenter={{ lat: 54.9786638, lng: -1.6101248 }}
          defaultZoom={13}
          options={{
            streetViewControl: false
          }}
        />
      </div>
    )
  }
}
