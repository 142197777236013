import React, { useState, useEffect } from 'react'
import SurveyPage from './SurveyPage'

const Results = (props) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [payload, setPayload] = useState([])

  useEffect(() => {
    const pages = props.pages.map((page, i) => ({
      pageNumber: i + 1,
      pageResults: {},
    }))

    setPayload(pages)
  }, [])

  const handlePayload = (pageResults, currentPage, direction) => {
    const newObject = Array.isArray(payload)
      ? payload.find((page) => page.pageNumber === currentPage + 1)
      : payload[currentPage]

    // Update new object
    newObject.pageResults = pageResults.questions.map((question) => {
      const determineValue = (q) => {
        const newQuestion = question.value.map((q) => {
          if (q.id || q.value) {
            return {
              id: q.id,
              value: q.value,
            }
          }
          // Otherwise return a string
          return q
        })

        return newQuestion
      }

      return {
        questionId: question.questionId,
        response: {
          questionId: question.questionId,
          value: Array.isArray(question.value)
            ? determineValue(question)
            : question.value,
        },
      }
    })

    /**
     * If we are updating the payload containing pre-defined data then it will be an array
     */
    if (Array.isArray(payload)) {
      const pageIndex = payload.findIndex(
        (item) => item.pageNumber === newObject.pageNumber
      )

      const newPayload = payload.map((item, index) => {
        if (index === pageIndex) {
          return {
            ...newObject,
          }
        }
        return item
      })

      setPayload({
        ...newPayload,
      })
    } else {
      setPayload({
        ...payload,
      })
    }

    if (direction == 'next') {
      setCurrentPage(currentPage + 1)
    } else if (direction == 'back') {
      setCurrentPage(currentPage - 1)
    }

    if (direction === 'submit') {
      props.onFormSubmit(payload)
    } else {
      props.onPageTransition(payload, currentPage)
    }
  }

  return props.pages.map((page, i) => (
    <SurveyPage
      initialAnswers={props.initialAnswers}
      error={props.error}
      hasResponded={props.hasResponded}
      surveyLength={props.pages.length}
      questions={page.questions}
      currentPage={currentPage}
      pageNumber={i + 1}
      key={i}
      onChange={(e) => props.onChange(e)}
      onPayloadChange={(pageResults, currentPage, direction) =>
        handlePayload(pageResults, currentPage, direction)
      }
    />
  ))
}

export default Results
