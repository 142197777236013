import React, { useState } from 'react'
import classnames from 'classnames'
import Rating from './Rating'
import ReportModal from './ReportModal'

const { id } = window.LTN.user

const Comment = ({ comment, isResult }) => {
  const [reportModal, setReportModal] = useState(false)
  const [reportedComment, setReportedComment] = useState({})

  const isAuthor = () => {
    if (window.LTN.user) {
      const { id } = window.LTN.user
      const authorId = comment.author.id

      return authorId === id
    }

    return false
  }

  const handleReportModal = () => {
    setReportedComment(comment)
    setReportModal(true)
  }

  return (
    <div
      className={classnames({
        'u-text-right c-wall__comment--author': isAuthor()
      })}
    >
      <p className="c-wall__comment">{comment.text}</p>

      <div className="c-wall__author c-wall__author--reply">
        <small>Posted by {comment.author.name.full}</small>
        <div className="c-wall__author-rating">
          <Rating
            comment={comment}
            isResult={isResult}
            handleReportModal={() => handleReportModal()}
          />
        </div>

        {reportModal && (
          <ReportModal
            closeModalCallback={() => setReportModal(false)}
            reportedComment={reportedComment}
          />
        )}
      </div>
    </div>
  )
}

export default Comment
