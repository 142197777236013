import React, { useState, useRef, useEffect } from 'react'
import CommentInput from './CommentInput'
import Comments from './Comments'
import axios from 'axios'

const { id, name } = window.LTN.user
const Modal = ({
  allowInteractions = true,
  content,
  handleModalClose,
  updateReplies,
  handleNewPin,
  position,
  updateSubReply,
  wallId,
  isResult
}) => {
  const [replyId, setReplyId] = useState()
  const [modalContent, setModalContent] = useState({})
  const [replies, setReplies] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (Object.keys(content).length > 0) {
      axios({
        method: 'get',
        url: `/api/comments/${
          content.comment ? content.comment.id : content.id
        }`,
        headers: {
          Authorization: `Bearer ${
            document.head.querySelector('meta[name="api-token"]').content
          }`
        }
      })
        .then(({ data }) => {
          setReplies(data.WallComment.replies)
          setLoading(false)
        })
        .catch(function(error) {
          console.log(error)
        })
    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    setModalContent(content)
    if (content && content.comment) {
      setReplyId(content.comment.id)
    } else {
      setReplyId(content.id)
    }
  }, [])

  const image = document.getElementById('image-survey-image')

  const firstCommentSubmit = comment => {
    const newComment = {
      text: comment,
      author: {
        name: name,
        id: id
      },
      replies: [],
      postedAt: new Date(Date.now()).toISOString()
    }

    if (position.lat) {
      newComment.position = { lat: position.lat, lng: position.lng }
    } else {
      newComment.position = {
        x: (position.x / image.clientWidth) * 100,
        y: (position.y / image.clientHeight) * 100
      }
    }

    axios
      .post(
        `/api/walls/${wallId}/comments`,
        {
          ...newComment
        },
        {
          headers: {
            Authorization: `Bearer ${
              document.head.querySelector('meta[name="api-token"]').content
            }`
          }
        }
      )
      .then(({ data }) => {
        setReplyId(data.WallComment.id)
        handleNewPin({ ...data.WallComment, position: newComment.position })
        setModalContent({ ...data.WallComment, position: newComment.position })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  const replySubmit = comment => {
    const reply = {
      text: comment,
      author: {
        name: name,
        id: id
      },
      postedAt: new Date(Date.now()).toISOString()
    }

    if (Object.keys(content).length > 0) {
      if (content.comment) {
        if (content.comment.position.lat) {
          reply.position = {
            lat: content.comment.position.lat,
            lng: content.comment.position.lng
          }
        } else {
          reply.position = {
            x: content.comment.position.x,
            y: content.comment.position.y
          }
        }
      }

      if (content.position) {
        if (content.position.lat) {
          reply.position = {
            lat: content.position.lat,
            lng: content.position.lng
          }
        } else {
          reply.position = {
            x: (position.x / image.clientWidth) * 100,
            y: (position.y / image.clientHeight) * 100
          }
        }
      }
    } else {
      reply.position = {
        x: (position.x / image.clientWidth) * 100,
        y: (position.y / image.clientHeight) * 100
      }
    }

    axios
      .post(
        `/api/walls/${wallId}/comments`,
        {
          text: reply.text,
          position: reply.position,
          reply_to_id: replyId,
          postedAt: new Date(Date.now()).toISOString()
        },
        {
          headers: {
            Authorization: `Bearer ${
              document.head.querySelector('meta[name="api-token"]').content
            }`
          }
        }
      )
      .then(({ data }) => {
        setReplies([...replies, reply])
        updateReplies([...replies, data.WallComment])
      })
      .catch(function(error) {
        console.log(error)
      })
  }
  const { comment, text } = modalContent

  const scrollRef = useRef(null)

  const scrollToBottom = () => {
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight
  }

  useEffect(() => {
    replies.length > 0 && scrollToBottom()
  }, [replies])

  return (
    <div role="dialog" tabindex="-1" aria-modal="true" className="c-wall__modal">
      {comment || text || replies.length > 0 ? (
        <div className="c-wall__modal-content">
          <div className="c-wall__title-block">
            <div>
              <h2 className="c-wall__title">{text || content.comment.text}</h2>
              <small className="c-wall__author">
                Posted by {comment ? comment.author.name.full : name.full}
              </small>
            </div>
            <button aria-label="close" className="c-wall__close" onClick={() => handleModalClose()}>
              <span aria-hidden="true"> x </span>
            </button>
          </div>
          <Comments
            loading={loading}
            updateSubReply={() => updateSubReply()}
            scrollRef={scrollRef}
            comments={replies}
            isResult={isResult}
          />
          {id ? (
            allowInteractions && <CommentInput
              placeholder="Add Comment"
              handleSubmitCallback={comment => replySubmit(comment)}
            />
          ) : (
            <div className="c-wall__modal-footer">
              <p>
                <a href="/login">Login</a> | <a href="/register">Register</a> to
                get involved in discussion
              </p>
            </div>
          )}
        </div>
      ) : (
        // If no content.text then this is a new comment
        <>
          <div className="c-wall__title-block">
            <div>
              <h2 className="c-wall__title">Start a discussion</h2>
            </div>
            <button className="c-wall__close" onClick={() => handleModalClose()}>
              x
            </button>
          </div>
          <CommentInput
            handleSubmitCallback={comment => firstCommentSubmit(comment)}
          />
        </>
      )}
    </div>
  )
}

export default Modal
