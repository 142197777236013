import React, { useState } from 'react'

const { id, name } = window.LTN.user

const CommentField = ({ isResult, submitCallback }) => {
  const [postedComment, setPostedComment] = useState('')
  const payload = {
    text: postedComment,
    author: {
      id: id,
      name: name
    },
    postedAt: new Date(Date.now()).toISOString()
  }

  return (
    <form
      onSubmit={e => {
        submitCallback(e, payload)
        setPostedComment('')
      }}
    >
      <div className="c-poll__input-group">
        <div className="c-poll__text-input">
          <textarea
            placeholder={
              !isResult
                ? 'Enter Your Comment'
                : 'The participation phase of this topic wall is now over.'
            }
            className="c-input c-input--full"
            value={postedComment}
            aria-label="Enter your comment for the consultation topic wall here"
            onChange={e => setPostedComment(e.target.value)}
            disabled={isResult}
          />
        </div>
        <div>
          <button
            aria-label="Send"
            type="submit"
            className="c-button c-button--medium c-button--quaternary-inverted"
            disabled={!id || isResult}
          >
            Send
          </button>
        </div>
      </div>
    </form>
  )
}

export default CommentField
