import localForage from 'localforage'
import queryString from 'query-string'

import { sprinkle } from './bootstrap'
import {
  MenuTrigger,
  TabView,
  ToggleDeleteModal,
  ForgotPasswordTrigger,
  TextToggle
} from './ui'
import {
  ConsultationFilter,
  ConsultationSort,
  EventSort
} from './ui/filter-sort'

document.addEventListener('DOMContentLoaded', () => {
  sprinkle()

  // Handle the Tab Selection Filtering
  if (document.querySelector('.c-filter-block__filter')) {
    const elements = document.querySelectorAll('.c-filter-block__filter')
    const query = queryString.parse(location.search, { sort: false })
    const keys = Object.keys(query)

    // If category selected then trigger that click
    if (keys.length > 0) {
      const index = keys.length - 1
      const lastKey = keys[index]

      if (lastKey.includes('category')) {
        document.querySelector('[data-filter-slug="category"]').click()
      }

      if (lastKey.includes('ward')) {
        document.querySelector('[data-filter-slug="ward"]').click()
      }

      if (lastKey.includes('status')) {
        document.querySelector('[data-filter-slug="status"]').click()
      }
    }
  }
})

/**
 * Text Field Toggle
 * Use this to toggle the display of a text field in a form.
 * E.g. clicking the 'other' option will present a text field
 */

const textToggleTriggers = document.querySelectorAll('.text-toggle-trigger-js')

if (textToggleTriggers && textToggleTriggers.length > 0) {
  for (let i = 0, len = textToggleTriggers.length; i < len; i++) {
    new TextToggle(textToggleTriggers[i])
  }
}

if (document.getElementById('menu-trigger-js')) {
  new MenuTrigger()
}

if (document.getElementById('change-password-js')) {
  new ForgotPasswordTrigger()
}

if (document.getElementById('filter-block')) {
  ConsultationFilter()
  ConsultationSort()
}

if (document.getElementById('trigger-deactivate-modal-js')) {
  ToggleDeleteModal()
}

if (document.getElementById('event-sort-by')) {
  EventSort()
}

const tabs = document.querySelectorAll('.c-tab-view')

if (tabs.length > 0) {
  // TODO - GET WORKING POLYFILL
  // TEMPORARY FIX FOR forEach ON IE11
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach
  }
  tabs.forEach(tab => {
    new TabView(tab)
  })
}

// Handle Profile Image updating
const profileImageContainer = document.getElementById(
  'profile-image-container-js'
)

if (profileImageContainer) {
  const profileImageEdit = document.getElementById('profile-image-edit-js')
  const profileImageForm = document.getElementById('profile-image-input-js')
  const profileImageFormText = document.getElementById(
    'profile-image-edit-text-js'
  )

  profileImageContainer.addEventListener('mouseenter', () => {
    profileImageEdit.classList.remove('o-media__figure-edit--hidden')
  })

  profileImageForm.onchange = () => {
    profileImageForm.submit()
  }

  profileImageFormText.onchange = () => {
    profileImageFormText.submit()
  }

  profileImageContainer.addEventListener('mouseleave', () => {
    profileImageEdit.classList.add('o-media__figure-edit--hidden')
  })
}

// Clear storage on logout/login
if (document.querySelectorAll('.auth-form').length) {
  const forms = document.querySelectorAll('.auth-form')

  forms.forEach(form => {
    form.addEventListener('submit', function(event) {
      event.preventDefault()

      // Clear storage prior to logging out/logging in
      localForage.clear()

      form.submit()
    })
  })
}

if (document.querySelectorAll('.select-text-toggle').length > 0) {
  const dropdowns = document.querySelectorAll('.select-text-toggle')

  dropdowns.forEach(dropdown => {
    const selectedTarget = dropdown.options[
      dropdown.selectedIndex
    ].getAttribute('data-toggle-target')

    console.log(selectedTarget)

    if (document.getElementById(selectedTarget)) {
      document.getElementById(selectedTarget).classList.add('is-active')
    }

    dropdown.addEventListener('change', function(event) {
      const target = event.target.options[
        event.target.selectedIndex
      ].getAttribute('data-toggle-target')

      if (
        event.target.value === 'prefer-to-self-describe' ||
        event.target.value === 'other'
      ) {
        if (target) {
          document.getElementById(target).classList.add('is-active')
        }
      } else {
        if (target) {
          document.getElementById(target).classList.remove('is-active')
        }
      }
    })
  })
}
