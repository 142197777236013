class ForgotPasswordTrigger {
  constructor(element = 'change-password-js') {
    this.element = document.getElementById(element)

    // Bind this to click callback
    this._handleClick = this._handleClick.bind(this)

    const self = this

    this.element.addEventListener('click', function(event) {
      self._handleClick()
    })
  }

  // If the user clicks on the element then we need to append
  // the active class to the menu, overlay and icon.
  _handleClick() {
    const email = this.element.getAttribute('data-email-address')
    const token = document.querySelector('[name="_token"]').value

    window.axios
      .post(
        '/profile/reset-password',
        {
          email,
          _token: token
        },
        {
          withCredentials: true
        }
      )
      .then(res => {
        if (res.status === 200) {
          alert(
            `An email was sent to email address registered with this account.`
          )
        } else {
          alert(
            `Sorry an error has occured. Please try again or contact us if the problem persists.`
          )
        }
      })
      .catch(err => {
        alert(
          `Sorry an error has occured. Please try again or contact us if the problem persists.`
        )
        console.log('There was an error', err)
      })
  }
}

export default ForgotPasswordTrigger
